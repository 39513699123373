import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import UserContext from '../../contexts/UserContext';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';

import './MedicalRecords.scss'
import AddMedicalRecordDialog from '../../components/AddMedicalRecordDialog/AddMedicalRecordDialog';
import dayjs from 'dayjs';
import { withCdnUrl } from '../../utils/common';
import { Avatar, Paper } from '@material-ui/core';
import { getDisplayName } from '../../utils/user';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import Pagination from '../../components/Pagination/Pagination';

class MedicalRecords extends Component {

  static contextType = UserContext;

  state = {
    medicalRecords: [],
    questionnaires: {},
    owner: null,
    addRecordDialogOpen: false,
    addRecordDialogMeta: '',
    page: 1,
    total: null,
    pageSize: 30,
  }

  componentDidMount() {
    document.title = "BankWell | Medical records";
    this.getMedicalRecords()
  }

  handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = {
        ...this.state,
        [e.target.name]: e.target.checked,
        //touchedFields: [ ...this.state.touchedFields, e.target.name ]
      };
      this.setState(newState);
    } else {
      newState = {
        ...this.state,
        [e.target.name]: e.target.value,
        //touchedFields: [ ...this.state.touchedFields, e.target.name]
      }
      this.setState(newState);
    }
  }

  validateForm = e => {
    let newState = {
      ...this.state,
      touchedFields: [...this.state.touchedFields, e.target.name, e.target.name]
    };
    this.setState(newState);

    let errors = {}

    // remove errors for non-touched field
    Object.keys(errors).forEach(err => {
      if (!newState.touchedFields.includes(err)) {
        delete errors[err]
      }
    })

    this.setState({ ...newState, errors })
  }

  errors = response => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all required fields')
    }
    if (!response.data.code && 400 === response.status) {
      toast.error('Form fields are not valid')
    }
  }

  getMedicalRecords = () => {
    let userId = this.props.match.params.userId || this.context.user.id;
    axiosInstance.get(`/users/${userId}/medical-records?size=${this.state.pageSize}&page=${this.state.page}`).then(response => {
      this.setState({
        medicalRecords: response.data.data,
        owner: response.data.owner,
        total: response.data.total,
        questionnaires: response.data.questionnaires
      })
    }).catch(err => { })
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.getMedicalRecords()
      })
    }
  }

  onMedicalRecordAdd = () => {
    this.setState({ addRecordDialogOpen: false });
    this.getMedicalRecords();
    this.context.getAuthUser();
  }

  render() {
    // let { user } = this.context;
    let state = this.state;

    return (
      <div className="MedicalRecords">
        {Object.keys(this.state.questionnaires).length > 0 && Object.values(this.state.questionnaires).filter(x => x !== true).length > 0 &&
          <div className="questionnaires p-4">
            <h3>Questionnaires waiting for upload</h3>
            <p>Please fill the related questionnaire before any meeting. So your therapist will have a better view of your condition.</p>
            <div className="items">
              {!this.state.questionnaires.ergonomy &&
                <Paper className="questionnaires__item">
                  <h4>Ergonomic assessment</h4>
                  <div className="actions">
                    <Button href={withCdnUrl('/public/questionnaires/ergonomic-assessment-form.pdf')} className="btn">Download From</Button>
                    <Button onClick={() => this.setState({ addRecordDialogOpen: true, addRecordDialogMeta: 'ergonomy' })}>Submit</Button>
                  </div>
                </Paper>
              }
              {!this.state.questionnaires.gynecology &&
                <Paper className="questionnaires__item">
                  <h4>Gynecology assessment</h4>
                  <div className="actions">
                    <Button download href={withCdnUrl('/public/questionnaires/gynecology-assessment-form.pdf')} className="btn">Download From</Button>
                    <Button onClick={() => this.setState({ addRecordDialogOpen: true, addRecordDialogMeta: 'gynecology' })}>Submit</Button>
                  </div>
                </Paper>
              }
              {!this.state.questionnaires.nutrition &&
                <Paper className="questionnaires__item">
                  <h4>Nutrition assessment</h4>
                  <div className="actions">
                    <Button href={withCdnUrl('/public/questionnaires/nutrition-assessment-form.pdf')} className="btn">Download From</Button>
                    <Button onClick={() => this.setState({ addRecordDialogOpen: true, addRecordDialogMeta: 'nutrition' })}>Submit</Button>
                  </div>
                </Paper>
              }
            </div>
          </div>
        }

        <header className="d-flex flex-center flex-space-between mb-3">
          <h6 className="fg-primary">
            {state.owner && this.context.user.id === state.owner.id
              ? <>Your Medical Records</>
              : <>{getDisplayName(state.owner)} Medical records</>}
          </h6>
          <Button onClick={() => this.setState({ addRecordDialogOpen: true })}>Add</Button>
        </header>

        {state.owner &&
          <AddMedicalRecordDialog
            addRecordDialogMeta={this.state.addRecordDialogMeta}
            open={state.addRecordDialogOpen}
            user={state.owner}
            onClose={() => { this.setState({ addRecordDialogOpen: false }) }}
            onSuccessfulSave={response => this.onMedicalRecordAdd(response)}
            onFileChange={() => { }}
          />
        }
        {state.medicalRecords.map(record => {

          let recordLink = `/panel/medical-records/${record._id}`;

          if (this.props.match.params.userId && this.context.user.id !== this.props.match.params.userId) {
            recordLink = `/user/${this.props.match.params.userId}/medical-records/${record._id}`;
          }

          return (
            <div className="records d-flex mb-3 pt-2 pb-2 b-b-regular" key={record._id}>
              <Link to={recordLink} className="recordLink flex-fill text-primary td-none">
                <h6 className="fs-p2 m-0 recordTitle">{record.title}</h6>
                <p className="recordAuthor text-secondary fs-p2 m-0">
                  {record.addedBy
                    ? <>{getDisplayName(record.addedBy)}</>
                    : <>{getDisplayName(record.owner)}</>
                  }
                </p>
              </Link>
              <div className="meta d-flex flex-center">
                <div className="time text-secondary">

                  {isBrowser ? dayjs(record.createdAt).format('MMMM, D YYYY H:m') : dayjs(record.createdAt).format('MMM, D YY')}
                </div>
                <div className={`user ${isBrowser ? 'ml-4' : 'ml-2'}`}>
                  {record.addedBy
                    ? <Avatar src={withCdnUrl(record.addedBy.photo)} alt={getDisplayName(record.addedBy)} />
                    : <Avatar src={withCdnUrl(record.owner.photo)} alt={getDisplayName(record.owner)} />
                  }
                </div>
              </div>
            </div>
          )
        })}
        {state.medicalRecords.length === 0 &&
          <h4 className="text-tertiary">No medical records submitted.</h4>
        }

        <Pagination
          className="d-flex flex-c-end"
          page={this.state.page}
          total={this.state.total}
          pageSize={this.state.pageSize}
          onPageChange={this.updatePage}
        />
      </div>
    );
  }
}

export default MedicalRecords;