import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';
import dayjs from 'dayjs';
import { formatPrice } from '../../utils/common';

import visa from '../../assets/icons/visa.svg';
import payPal from '../../assets/icons/payPal.svg';
import masterCard from '../../assets/icons/masterCard.svg';

import './PaymentDialog.scss'
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useUserContext } from '../../contexts/UserContext';

const PaymentDialog = props => {

  const {user} = useUserContext();
  const [state, setState] = React.useState({
    useBalance: false
  })

  const doPay = e => {
    axiosInstance.patch(`payments/${props.appointment.payment._id}`, {
      useBalance: state.useBalance
    }).then(response => {
      props.onSuccessPayment(response)
    }).catch(() => {
      toast.error('Error during payment')
    })
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className:'pl-4 pr-4 pt-2 pb-2' }}
        className="PaymentDialog centered"
      >
        <DialogTitle id="alert-dialog-title">
          Payment
        </DialogTitle>
        <DialogContent
        
        >
          <div className="infoWrapper mb-4">
            <div className="info mb-2 d-flex flex-space-between">
              <div className="label">{dayjs(props.appointment.end).diff(props.appointment.start, 'minutes')} minutes session</div>
              <div className="value">{formatPrice(props.appointment.payment.amount)}</div>
            </div>
            <div className="info mb-2 d-flex flex-space-between">
              <div className="label">Tax</div>
              <div className="value">{formatPrice(0)}</div>
            </div>
            <div className="info mb-2 d-flex flex-space-between">
              <div className="label fw-bold">Total</div>
              <div className="value fw-bold">{formatPrice(props.appointment.payment.amount)}</div>
            </div>
          </div>
          <div style={{display:'none'}}>
            <span className="d-flex mb-2 fs-sm text-primary">Add Credit / Debit card</span>
            <TextField
              margin="dense"
              id="cardOwner"
              name="cardOwner"
              label="Card holder's name"
              placeholder="Card holder's name"
              type="text"
              fullWidth
              variant="outlined"
              disabled //required
              size="small"
              InputLabelProps={{ shrink: true, }}
            />
            <FormGroup className="mt-2">
              <div className="cardNumberWrapper">
                <TextField
                  margin="dense"
                  id="cardOwner"
                  name="cardOwner"
                  label="Card Number"
                  placeholder="Card Number"
                  type="text"
                  fullWidth
                  variant="outlined"
                  disabled //required
                  size="small"
                  InputLabelProps={{ shrink: true, }}
                />
                <div className="methods">
                  <img src={visa} alt="visa" className="method" />
                  <img src={payPal} alt="visa" className="method" />
                  <img src={masterCard} alt="visa" className="method" />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <span className="d-flex mb-2 fs-sm text-primary">Expiration date</span>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="cardMonth"
                    name="cardMonth"
                    label="Month"
                    placeholder="MM"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled //required
                    size="small"
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="cardYear"
                    name="cardYear"
                    label="Year"
                    placeholder="YYYY"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled //required
                    size="small"
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xs-12 col-md-6">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="cardCvc"
                    name="cardCvc"
                    label="CVC"
                    placeholder="Enter CVC"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled //required
                    size="small"
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
              </div>
            </FormGroup>

            <FormControlLabel
              value={state.confirm}
              checked={state.confirm}
              disabled={!user.balance || user.balance < props.appointment.payment.amount}
              control={<Checkbox onChange={
                e => { setState({ ...state,useBalance: e.target.checked }) }
              } />}
              label={`Use my balance (current balance is ${formatPrice(user.balance)})`}
              labelPlacement="end"
            />
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.onClose} color="primary">
            Cancel
          </Button> */}
          <Button
            onClick={doPay}
            color="primary"
            style={{
              borderRadius:'30px',
              padding:'.4rem 3rem'
            }}
            disableElevation
            variant="contained"
          // disabled={!amount}
          >
            Book For Free
          </Button>
          <div style={{
            fontSize: '11pt',
            marginLeft: '10px',
            color: 'green',
            fontWeight: 'bold'
          }}>Limited Time; Free During Beta Testing</div>
        </DialogActions>        
      </Dialog>
    </>
  );
};

export default PaymentDialog;