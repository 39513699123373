const CheckIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489 489">
          <path fill={color || '#333333'} d="M242 6a238 238 0 105 477 238 238 0 00-5-477zm2 34a205 205 0 01187 288 205 205 0 01-391-99A206 206 0 01245 40z" />
          <circle fill={color || '#333333'} cx="244.5" cy="244.5" r="185.4" paintOrder="stroke fill markers" />
        </svg>
        : <svg width={size} height={size} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489 489">
          <path fill={color || '#333333'} d="M242 6a238 238 0 105 477 238 238 0 00-5-477zm2 34a205 205 0 01187 288 205 205 0 01-391-99A206 206 0 01245 40z" />
        </svg>
      }
    </>
  );
};

export default CheckIcon;