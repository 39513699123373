import Avatar from '@material-ui/core/Avatar';
import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import style from './Notifications.module.scss';
import systemAvatar from '../../assets/images/system.png';
import dayjs from 'dayjs';
import Pagination from '../../components/Pagination/Pagination';
import UserContext from '../../contexts/UserContext';
import { axiosInstance } from '../../axiosConfig';
import { CircularProgress } from '@material-ui/core';
import AddTherapistReviewDialog from '../../components/AddTherapistReviewDialog/AddTherapistReviewDialog';

class Notifications extends Component {

  static contextType = UserContext;

  state = {
    notifications: [],
    isLoading: false,
    page: 1,
    total: 8,
    pageSize: 50,
    reviewDialogOpen: false
  }

  notificationUser = null;

  componentDidMount() {
    document.title = "BankWell | Notifications";
    this.getNotifications();
  }

  getNotifications() {
    this.setState({ isLoading: true });
    axiosInstance.get(`notifications?size=${this.state.pageSize}&page=${this.state.page}`)
      .then(response => {
        this.setState({
          notifications: response.data.data,
          total: response.data.total,
          page: response.data.page,
          isLoading: false
        })
        this.markNotificationsRead();
        this.context.setNewNotificationCount(response.data.new)
      }).catch(err => {
        this.setState({ isLoading: false })
      })
  }

  markNotificationsRead() {
    axiosInstance.patch('notifications/mark-all-read').then(response => {
      this.context.setNewNotificationCount(0)
    }).catch(() => {
      this.setState({ loaded: true })
    })
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.getNotifications()
      })
    }
  }
  render() {
    return (
      <div className="Notifications">
        <Header backButton={true} />
        <div className={`container content`}>
          <h6 className="fg-primary fw-bold pb-2 mb-3">Notifications</h6>
          {this.state.isLoading ?
            <CircularProgress style={{ display: 'block', margin: '2rem auto 0 auto' }} />
            : <>
              {this.state.notifications.length ? this.state.notifications.map((notification, i) => (
                <div
                  key={i}
                  onClick={() => {
                    if (notification.user) {
                      this.setState({ reviewDialogOpen: true });
                      this.notificationUser = notification.user
                    }
                  }}
                  className={`${!notification.read && style.unread} ${style.notification} d-flex mb-3 pt-2 pb-2`}
                >
                  <div className="info flex-fill">
                    <h6 className="fs-p2 m-0">{notification.title}</h6>
                    <p className={`${style.description} text-secondary fs-p2 m-0`}>{notification.content}</p>
                  </div>
                  <div className={`${style.meta} d-flex flex-center`}>
                    <div className="time text-secondary">
                      {dayjs(notification.createdAt).format('MMMM, D YYYY H:m')}
                    </div>
                    <div className={`${style.avatarWrapper} user ml-4`}>
                      {notification.user ?
                        <Avatar src={withCdnUrl(notification.user.photo)} alt={getDisplayName(notification.user)} />
                        : <Avatar src={systemAvatar} alt="system" />
                      }
                    </div>
                  </div>
                </div>
              ))
                :
                <div className="noDataMessage">
                  <h6 className="title">No notification</h6>
                  <p className="title">You do not have a new notification at this time </p>
                </div>
              }
            </>
          }

          {this.notificationUser &&
            <AddTherapistReviewDialog
              open={this.state.reviewDialogOpen}
              user={this.notificationUser}
              onAdd={() => {
                this.setState({ reviewDialogOpen: false })
              }}
              onClose={() => this.setState({ reviewDialogOpen: false })}
            />
          }

          {this.state.total > this.state.pageSize &&
            <Pagination
              className={style.pagination}
              page={this.state.page}
              total={this.state.total}
              pageSize={this.state.pageSize}
              onPageChange={this.updatePage}
            />
          }
        </div>
        <Footer />
      </div>
    );
  }
}

export default Notifications;