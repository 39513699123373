import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';
import { getDisplayName } from '../../utils/user';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useUserContext } from '../../contexts/UserContext';
import { formatPrice } from '../../utils/common';

const AddCreditDialog = props => {

  const { user } = useUserContext();
  const [amount, setAmount] = React.useState('');
  const [state, setState] = React.useState({
    useBalance: false
  })

  const addCredit = e => {
    axiosInstance.post(`payments`, {
      amount: amount/600000,
      receiver: props.receiver ? props.receiver.id : undefined,
      useBalance: state.useBalance || undefined
    }).then(response => {
      props.onSuccessPayment(response)
    }).catch(() => {
      toast.error('Error during payment')
    })
  }

  return (
    <>
      <Dialog
        open={props.paymentDialogOpen}
        onClose={props.onClose}
        PaperProps={{ className: 'pl-4 pr-4 pt-2 pb-2' }}
        className="centered"
      >
        <DialogTitle id="alert-dialog-title">
          {props.receiver
            ? <>Add credit for {getDisplayName(props.receiver)}</>
            : <>Add credit</>
          }
        </DialogTitle>
        <DialogContent>
          <FormLabel className="d-flex mb-1">Set the amount you want to charge</FormLabel>
          <TextField
            // error={!!this.state.errors.amount}
            // helperText={this.state.errors.amount}
            // onBlur={this.validateForm}
            autoFocus
            margin="dense"
            id="amount"
            name="amount"
            label="Amount"
            placeholder="Enter Amount"
            type="number"
            fullWidth
            variant="outlined"
            required
            InputLabelProps={{ shrink: true, }}
            onChange={e => setAmount(e.target.value)}
          />
          <div style={{display:'none'}}>
            <FormGroup className="mt-3">
              <FormLabel className="d-flex mb-1">Card Owner</FormLabel>
              <TextField
                margin="dense"
                id="cardOwner"
                name="cardOwner"
                label="Card holder's name"
                placeholder="Card holder's name"
                type="text"
                fullWidth
                variant="outlined"
                disabled //required
                InputLabelProps={{ shrink: true, }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <FormLabel className="d-flex mb-1">Expiration date</FormLabel>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="cardMonth"
                    name="cardMonth"
                    label="Month"
                    placeholder="MM"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled //required
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
                <div className="col-xs-12 col-md-6">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="cardYear"
                    name="cardYear"
                    label="Year"
                    placeholder="YYYY"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled //required
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xs-12 col-md-6">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="cardCvc"
                    name="cardCvc"
                    label="CVC"
                    placeholder="Enter CVC"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled //required
                    InputLabelProps={{ shrink: true, }}
                  />
                </div>
            </div>
          </FormGroup>
          {props.receiver &&
            <FormControlLabel
              disabled={!user.balance || user.balance < amount}
              control={<Checkbox onChange={
                e => { setState({ ...state, useBalance: e.target.checked }) }
              } />}
              label={`Use my balance (current balance is ${formatPrice(user.balance)})`}
              labelPlacement="end"
            />
          }
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.onClose} color="primary">
            Cancel
          </Button> */}
          <Button
            onClick={addCredit}
            color="primary"
            style={{
              borderRadius: '30px',
              padding: '.4rem 3rem'
            }}
            disableElevation
            variant="contained"
            disabled={!amount}
          >
            Next
          </Button>
          <div style={{
            fontSize: '11pt',
            marginLeft: '10px',
            color: 'green',
            fontWeight: 'bold'
          }}>Limited Time; Free During Beta Testing</div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCreditDialog;