import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import dayjs from 'dayjs';
import React from 'react';
import { formatPrice, withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import MenuIcon from '../icons/MenuIcon';
import StopIcon from '../icons/StopIcon';
import RefreshIcon from '../icons/RefreshIcon';
import AddCreditDialog from '../AddCreditDialog/AddCreditDialog';
import { isBrowser } from 'react-device-detect';

const EmployeeItem = ({ employee, isInvited, sendInvitation, cancelInvitation }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false)
  const [paymentResultDialogOpen, setPaymentResultDialogOpen] = React.useState(false)
  const handleMenuClick = e => setAnchorEl(e.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const onSuccessPayment = (response) => {
    setPaymentDialogOpen(false)
    setPaymentResultDialogOpen(true)
  }

  let avatarStyle = isBrowser ?
    {
      width: '80px',
      height: '80px'
    } :
    {
      width: '40px',
      height: '40px'
    }

  return (
    <div className="d-flex flex-center pt-3 pb-3">
      <div className="avatarWrapper">
        <Avatar src={withCdnUrl(employee.photo)} style={avatarStyle} />
      </div>
      <div className="contentWrapper flex-fill ml-3">
        {isInvited
          ? <><h6 className="fs-p2 d-flex flex-center">
            Invited User
          </h6>
            <div className="name text-secondary">{getDisplayName(employee)}</div>
            <div className="date text-secondary">{dayjs(employee.createdAt).format('MMMM D, YYYY H:mm')}</div>
          </>
          : <>
            <h6 className="fs-p2 d-flex flex-center"> {getDisplayName(employee)}</h6>
            <div className="email text-secondary fw-medium">{employee.email}</div>
            <div className="balance text-tertiary fw-regular">balance: {formatPrice(employee.balance)}</div>
          </>
        }

      </div>
      <div className="actionsWrapper flex-center">
        {!isInvited &&
          <Button
            onClick={() => setPaymentDialogOpen(true)}
            className="rounded"
            disableElevation
            variant="contained"
            style={{ borderRadius: '30px', textTransform: 'inherit', fontSize: !isBrowser ? '12px' : '' }}
          ><RefreshIcon color="white" className="mr-1" size={17} /> Recharge</Button>
        }

        {isInvited &&
          <Button
            onClick={sendInvitation}
            className="rounded"
            disableElevation
            variant="contained"
            style={{ borderRadius: '30px', textTransform: 'inherit' }}
          >
            {isBrowser
              ? <span><RefreshIcon color="white" className="mr-1 va-middle" size={17}/> Resend invitation</span>
              : <RefreshIcon color="white" className="mr-1" size={17} />
            }
          </Button>
        }

        {isInvited &&
          <>
            <IconButton
              onClick={handleMenuClick}
              style={{
                marginLeft: '.4rem',
                padding: '.7rem'
              }}
            >
              <MenuIcon color="#333" size="15" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={cancelInvitation}
              ><StopIcon outline className="mr-2" /> Cancel invitation </MenuItem>
            </Menu>
          </>}

        <AddCreditDialog
          receiver={employee}
          paymentDialogOpen={paymentDialogOpen}
          onClose={() => setPaymentDialogOpen(false)}
          onSuccessPayment={response => onSuccessPayment(response)}
        />
        <Dialog
          open={paymentResultDialogOpen}
          onClose={() => setPaymentResultDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Credit added successfully</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Adding credit for {getDisplayName(employee)} was successful.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setPaymentResultDialogOpen(false)}
              color="primary"
            >
              OK
          </Button>
          </DialogActions>
        </Dialog>

      </div>
    </div>
  );
};

export default EmployeeItem;