
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import Avatar from '@material-ui/core/Avatar';
import { axiosInstance } from '../../axiosConfig';
import toast from 'react-hot-toast';
import UserContext from '../../contexts/UserContext';
import Pagination from '../../components/Pagination/Pagination';
import { formatPrice, withCdnUrl } from '../../utils/common';
import dayjs from 'dayjs';
import { getDisplayName } from '../../utils/user';
import AddCreditDialog from '../../components/AddCreditDialog/AddCreditDialog';
import { isBrowser } from 'react-device-detect';

class Transactions extends Component {

  static contextType = UserContext;

  state = {
    user: {},
    transactions: [],
    paymentDialogOpen: false,
    paymentResultDialogOpen: false,
    withdrawDialogOpen: false,
    amount: '',
    errors: [],
    touchedFields: [],
    page: 1,
    total: null,
    pageSize: 30,
  }

  componentDidMount() {
    document.title = "BankWell | Transactions";

    this.context.getAuthUser();
    this.getUserTransactions()
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.getUserTransactions()
      })
    }
  }

  getUserTransactions() {
    axiosInstance.get(`/payments?size=${this.state.pageSize}&page=${this.state.page}`).then(response => {
      this.setState({
        transactions: response.data.data,
        total: response.data.total,
      })
    }).catch(err => { })
  }

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  validateForm = e => {
    let newState = {
      touchedFields: [...this.state.touchedFields, e.target.name, e.target.name]
    };
    this.setState(newState);
    let errors = {}
    if (!this.state.amount) {
      errors.amount = 'Please fill out this field';
    }
    // remove errors for non-touched field
    Object.keys(errors).forEach(err => {
      if (!newState.touchedFields.includes(err)) {
        delete errors[err]
      }
    })

    this.setState({ ...newState, errors })
  }

  onSuccessPayment = response => {
    this.getUserTransactions()
    this.context.setUser(response.data);
    this.setState({
      paymentDialogOpen: false,
      paymentResultDialogOpen: true
    })
  }

  submitWithdraw = e => {
    this.setState({ withdrawDialogOpen: false })
    toast.success('Withdrawal request sent')
  }

  render() {
    return (
      <div className="Transactions">
        <header className="d-flex flex-center flex-space-between mb-3">
          <h6 className="fg-primary">Your Balance: {formatPrice(this.context.user.balance || 0)}</h6>
          {'consultant' === this.context.user.type
            ? <Button onClick={() => this.setState({ withdrawDialogOpen: true })}>Withdraw</Button>
            : <Button onClick={() => this.setState({ paymentDialogOpen: true })}>Add Credit</Button>
          }
        </header>

        {this.state.transactions.map((transaction, i) => (
          <div className="transaction d-flex mb-3 pt-2 pb-2" key={i}>
            <div className="info flex-fill">
              <h6 className="fs-p2 m-0">{dayjs(transaction.createdAt).format('MMMM, D YYYY H:m')}</h6>
              <p className={`text-secondary m-0 ${isBrowser ? 'fs-p2' : 'fs-sm'}`}>
                {this.context.user.id === transaction.buyer.id && transaction.receiver === undefined && transaction.items.length === 0 && !transaction.provider &&
                  <>Deposit</>
                }
                {this.context.user.id === transaction.buyer.id && transaction.receiver === undefined && transaction.items.length === 0 && transaction.provider &&
                  <>Payment by {transaction.provider}</>
                }
                {this.context.user.id === transaction.buyer.id && transaction.receiver && transaction.items.length === 0 &&
                  <>Adding credit to {getDisplayName(transaction.receiver)}</>
                }
                {transaction.receiver && this.context.user.id === transaction.receiver.id && transaction.items.length === 0 &&
                  <>{getDisplayName(transaction.buyer)} Added to your credit </>
                }
                {transaction.receiver && this.context.user.id === transaction.receiver.id && transaction.items.length > 0 && 'consultant' !== transaction.buyer.type &&
                  <>Session with {getDisplayName(transaction.buyer)}</>
                }
                {transaction.receiver && this.context.user.id === transaction.receiver.id && transaction.items.length > 0 && 'consultant' === transaction.buyer.type &&
                  <>Session with {getDisplayName(transaction.buyer)} canceled</>
                }
                {this.context.user.id === transaction.buyer.id && transaction.items.length > 0 && 'consultant' !== transaction.buyer.type &&
                  <>Session with {getDisplayName(transaction.receiver)}</>
                }
                {this.context.user.id === transaction.buyer.id && transaction.items.length > 0 && 'consultant' === transaction.buyer.type &&
                  <>Session with {getDisplayName(transaction.receiver)} canceled</>
                }
              </p>
            </div>
            <div className="meta d-flex flex-center">
              <div className="time fw-bold">
                {(this.context.user.id === transaction.buyer.id && transaction.receiver) && transaction.items.length === 0 &&
                  <span className="fg-red">{formatPrice(transaction.amount)}</span>
                }
                {(this.context.user.id === transaction.buyer.id && transaction.items.length > 0) &&
                  <span className="fg-red">{formatPrice(transaction.amount)}</span>
                }

                {this.context.user.id === transaction.buyer.id && transaction.receiver === undefined && transaction.items.length === 0 &&
                  <span className="fg-primary">{formatPrice(transaction.amount)}</span>
                }
                {this.context.user.id !== transaction.buyer.id &&
                  <span className="fg-primary">{formatPrice(transaction.amount)}</span>
                }
              </div>
              <div className={`user ${isBrowser ? 'ml-4' : 'ml-2'}`}>
                {
                  (!transaction.receiver || (transaction.receiver && transaction.receiver.id === this.context.user.id)) &&
                    transaction.buyer && 'employer' !== this.context.user.type 
                    ? <Avatar src={withCdnUrl(transaction.buyer.photo)} alt={getDisplayName(transaction.buyer)} />
                    : <Avatar src={withCdnUrl((transaction.receiver && transaction.receiver.photo) || transaction.buyer.photo)} alt={getDisplayName(transaction.receiver)} />
                }
              </div>
            </div>
          </div>
        ))}

        <Pagination
          className="d-flex flex-c-end"
          page={this.state.page}
          total={this.state.total}
          pageSize={this.state.pageSize}
          onPageChange={this.updatePage}
        />
        <AddCreditDialog
          paymentDialogOpen={this.state.paymentDialogOpen}
          onClose={() => this.setState({ paymentDialogOpen: false })}
          onSuccessPayment={response => this.onSuccessPayment(response)}
        />
        <Dialog
          open={this.state.paymentResultDialogOpen}
          onClose={() => this.setState({ paymentResultDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Payment successful</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your new balance is {formatPrice(this.context.user.balance)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ paymentResultDialogOpen: false })}
              color="primary"
            >
              OK
          </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.withdrawDialogOpen}
          onClose={() => this.setState({ withdrawDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Withdraw</DialogTitle>
          <DialogContent>
            <DialogContentText className="mb-3">
              Your current balance is {formatPrice(this.context.user.balance)}
            </DialogContentText>
            <FormGroup className="mt-3">
              <TextField
                error={!!this.state.errors.amount}
                helperText={this.state.errors.amount}
                onBlur={this.validateForm}
                inputProps={{ max: this.context.user.balance }}
                autoFocus
                margin="dense"
                id="amount"
                name="amount"
                label="Amount"
                placeholder="Enter Amount"
                type="number"
                fullWidth
                variant="outlined"
                required
                InputLabelProps={{ shrink: true, }}
                onChange={this.handleChange}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ withdrawDialogOpen: false })} color="primary">
              Cancel
          </Button>
            <Button
              onClick={this.submitWithdraw}
              color="primary"
              disabled={this.state.touchedFields.length < 1 || this.state.errors.length > 0}
            >
              Submit Request
          </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default Transactions;