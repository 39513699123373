import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';
import EmployeeItem from '../../components/EmployeeItem/EmployeeItem';
import Pagination from '../../components/Pagination/Pagination';
import UserContext from '../../contexts/UserContext';
import { isValidEmail } from '../../utils/common';

class Employees extends Component {

  static contextType = UserContext;

  state = {
    user: {},
    invitations: [],
    employees: [],
    inviteDialogOpen: false,
    email: '',
    emailValid: true,
    page: 1,
    total: null,
    pageSize: 50,
  }

  componentDidMount() {
    document.title = "BankWell | Employees";
    this.getInvitations()
    this.getEmployees()
  }

  handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = { [e.target.name]: e.target.checked };
      this.setState(newState);
    } else {
      newState = { [e.target.name]: e.target.value }
      this.setState(newState);
    }

    this.validateForm(newState)
  }

  errors = response => {
    if (409 === response.status) {
      toast.error('User already exists')
    }
    if ('INVITATION_ALREADY_SENT' === response.data.code) {
      toast.error('An Invitation is already sent in last 1 hour')
    }
  }

  validateForm = newState => {
    let emailValid = true;
    if (!isValidEmail(newState.email)) {
      emailValid = false
    }
    this.setState({ emailValid })
  }

  getInvitations() {
    axiosInstance.get(`companies/${this.context.user.id}/invitations`).then(response => {
      this.setState({ invitations: response.data })
    }).catch(err => {
      this.errors(err.response)
    })
  }

  getEmployees() {
    axiosInstance.get(`companies/${this.context.user.id}/employees?size=${this.state.pageSize}&page=${this.state.page}`)
      .then(response => {
        this.setState({ employees: response.data.data, total: response.data.total })
      }).catch(err => { })
  }

  sendInvitation = email => {
    axiosInstance.post(`companies/${this.context.user.id}/invitations`, {
      email: email ? email : this.state.email
    }).then(() => {
      toast.success('Invitation sent');
      this.setState({ inviteDialogOpen: false })
      this.getInvitations();
    }).catch(err => {
      this.errors(err.response)
    })
  }

  cancelInvitation = email => {
    axiosInstance.delete(`companies/${this.context.user.id}/invitations`, {
      data:{email: email}
    }).then(() => {
      toast.success('Invitation removed');
      this.getInvitations();
    }).catch(err => {
      this.errors(err.response)
    })
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.getEmployees()
      })
    }
  }

  render() {
    const { invitations, employees } = this.state;

    return (
      <>
        <header className="d-flex flex-center flex-space-between mb-3 b-b-regular pb-2">
          <h6 className="fg-primary fw-bold">Invited</h6>
          <Button onClick={() => this.setState({ inviteDialogOpen: true })}>Add</Button>
          <Dialog
            open={this.state.inviteDialogOpen}
            onClose={() => this.setState({ inviteDialogOpen: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Invite Employee</DialogTitle>
            <DialogContent>
              <FormGroup className="mt-0">
                <TextField
                  error={!this.state.emailValid}
                  // autoFocus
                  margin="dense"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  required
                  InputLabelProps={{ shrink: true, }}
                  placeholder="Email Address"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ inviteDialogOpen: false })} color="primary">
                Cancel
          </Button>
              <Button
                onClick={() => this.sendInvitation()}
                color="primary"
                disabled={this.state.email.length < 1 || !this.state.emailValid}
              >
                Send Invitation
          </Button>
            </DialogActions>
          </Dialog>
        </header>
        {invitations.map(employee =>
          <EmployeeItem
            key={employee._id}
            isInvited
            cancelInvitation={() => this.cancelInvitation(employee.email)}
            sendInvitation={() => this.sendInvitation(employee.email)}
            employee={employee}
          />
        )}
        {invitations.length === 0 &&
          <p className="text-secondary">No invitations</p>
        }

        {employees.length > 0 &&
          <>
            <h6 className="mt-4 fg-primary fw-bold b-b-regular pb-2">Joined ({this.state.total})</h6>
            {employees.map(employee =>
              <EmployeeItem employee={employee} key={employee._id} />
            )}

            <Pagination
              className="d-flex flex-c-end"
              page={this.state.page}
              total={this.state.total}
              pageSize={this.state.pageSize}
              onPageChange={this.updatePage}
            />
          </>
        }
      </>
    );
  }
}

export default Employees;