import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './Settings.scss';
import { StaticDatePicker } from '@material-ui/lab';
import PickersDay from '@material-ui/lab/PickersDay';
import UserContext from '../../contexts/UserContext';
import { axiosInstance } from '../../axiosConfig';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import CheckIcon from '../../components/icons/CheckIcon';
import toast from 'react-hot-toast';

dayjs.extend(isToday);

class Settings extends Component {

  static contextType = UserContext;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  state = {
    user: {},
    date: new Date(),
    workingHours: {},
    sessionPrice: this.context.user.sessionPrice,
    charityEnabled: this.context.user.charityEnabled
  }

  componentDidMount() {
    document.title = "BankWell | Settings";
    this.getAvailableTimes();
  }

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  getAvailableTimes = () => {
    axiosInstance.get(`/working-hours/${this.context.user.id}`).then(response => {
      this.setState({ workingHours: response.data })
    }).catch(err => { })
  }

  parseHours() {
    let hours = []
    for (let h = 6; h < 23; h++) {
      for (let m = 0; m < 60; m += 15) {
        hours.push(`${h}:${m.toString().padStart(2, '0')}`)
      }
    }
    hours.push(`23:00`);
    return hours;
  }

  toggleWeekly = (e, time) => {
    const selectedDate = dayjs(this.state.date).format('YYYY-MM-D');
    axiosInstance.patch('working-hours', {
      start: dayjs(`${selectedDate} ${time.time}`),
      end: dayjs(`${selectedDate} ${time.time}`).add(45, 'm'),
      weekly: e.target.checked
    }).then(() => {
      this.getAvailableTimes()
    }).catch(err => {
    })
  }

  toggleWorkingHour = (e, time) => {
    if (e.target && 'checkbox' === e.target.type) return;
    const selectedDate = dayjs(this.state.date).format('YYYY-MM-D');
    const workingTimes = this.getSelectedDateWorkingHours();

    if (workingTimes.find(t => t.time === time.time)) {
      axiosInstance.delete('working-hours', {
        data: {
          start: dayjs(`${selectedDate} ${time.time}`),
          end: dayjs(`${selectedDate} ${time.time}`).add(45, 'm')
        }
      }).then(() => {
        this.getAvailableTimes()
      }).catch(err => { })

    } else {
      axiosInstance.post('working-hours', {
        start: dayjs(`${selectedDate} ${time}`),
        end: dayjs(`${selectedDate} ${time}`).add(45, 'm')
      }).then(() => {
        this.getAvailableTimes()
      }).catch(err => {
        if ('WORKING_HOUR_CONFLICT' === err.response.data.code) {
          toast.error('This working hour range is already occupied.')
        }
      })
    }
  }

  getSelectedDateWorkingHours() {
    let dateWorkingHours = this.state.workingHours[dayjs(this.state.date).format('YYYY-MM-D')];
    let workingTimes = [];

    if (dateWorkingHours) {
      dateWorkingHours.forEach((workingHour, i) => {
        workingTimes[i] = {
          time: dayjs(workingHour.start).format('H:mm'),
          weekly: workingHour.isWeekly ? true : false,
        }
        // workingTimes.push(dayjs(workingHour.start).add(15, 'm').format('H:mm'))
        // workingTimes.push(dayjs(workingHour.start).add(30, 'm').format('H:mm'))
      })
    }
    return workingTimes;
  }

  toggleCharity = e => {
    this.setState({ charityEnabled: !this.state.charityEnabled })
    axiosInstance.patch(`users/${this.context.user.id}`, {
      charityEnabled: e.target.checked,
    }).then(() => {
    }).catch(err => { })
  }

  setSessionCost = e => {
    if (+e.target.value === +this.context.user.sessionPrice) return;
    axiosInstance.patch(`users/${this.context.user.id}`, {
      sessionPrice: +e.target.value,
    }).then(() => {
      toast.success('session price saved')
    }).catch(() => { toast.error('failed to set session price') })
  }

  errors = response => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all required fields')
    }
    if ('MISMATCH_PASSWORDS' === response.data.code) {
      toast.error('Password mismatch')
    }
    if ('INVALID_PASSWORD' === response.data.code) {
      toast.error('Invalid Password')
    }
    if ('WEAK_PASSWORD' === response.data.code) {
      toast.error('Please choose stronger password')
    }
    if ('ALREADY_EXISTS' === response.data.code && 'phone' === response.data.vars) {
      toast.error('Phone number already taken')
    }
    if (!response.data.code && 400 === response.status) {
      toast.error('Form fields are not valid')
    }
  }

  /**
   * The password change option is exists in account information page -
   * so it should be removed from here. That's the reason i don't make it a component.
   */
  changePassword = e => {
    axiosInstance.patch(`users/${this.context.user.id}/password`, {
      password: this.state.oldPass,
      newPassword: this.state.newPass,
      confirmPassword: this.state.confirmPass,
    }).then(response => {
      toast.success('Password changed successfully');
      this.setState({ passwordDialogOpen: false })
    }).catch(err => {
      this.errors(err.response)
    })
  }

  render() {
    const workingTimes = this.getSelectedDateWorkingHours();
    return (
      <div className="Settings">
        <h6 className="fg-primary fw-bold b-b-regular pb-2">Settings</h6>
        <div className="section changePassword pt-3 pb-3 b-b-regular mb-3">
          <Button
            onClick={e => this.setState({ passwordDialogOpen: true })}
          >Change Password</Button>
          <Dialog open={this.state.passwordDialogOpen} onClose={() => this.setState({ passwordDialogOpen: false })}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <form autoComplete="off" onSubmit={this.changePassword}>
                <TextField
                  autoFocus
                  InputLabelProps={{ shrink: true, }}
                  margin="dense"
                  id="name"
                  name="oldPass"
                  onChange={this.handleChange}
                  label="Old password"
                  placeholder="Enter your current password"
                  type="password"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ autocomplete: "false" }}
                  margin="dense"
                  id="newPass"
                  name="newPass"
                  onChange={this.handleChange}
                  label="Password"
                  placeholder="New password"
                  type="password"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  InputLabelProps={{ shrink: true, }}
                  inputProps={{ autocomplete: "false" }}
                  margin="dense"
                  id="confirmPass"
                  name="confirmPass"
                  onChange={this.handleChange}
                  label="Confirm"
                  placeholder="Re-type new password"
                  type="password"
                  fullWidth
                  variant="standard"
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ passwordDialogOpen: false })}>Cancel</Button>
              <Button onClick={this.changePassword}>Change Password</Button>
            </DialogActions>
          </Dialog>
        </div>
        {'consultant' === this.context.user.type &&
          <div className="section cost pt-2 pb-2 ">
            <TextField
              required
              // autoFocus
              value={this.state.sessionPrice}
              margin="dense"
              id="sessionPrice"
              name="sessionPrice"
              label="Price per session(45Mins)"
              fullWidth
              InputLabelProps={{ shrink: true, }}
              placeholder="Tap to edit"
              variant="standard"
              type="number"
              inputProps={{ max: 1000, maxLength: 4 }}
              onBlur={this.setSessionCost}
              onChange={this.handleChange}
            />
          </div>
        }
        <div className="section charity pt-2 pb-2 ">
          <FormControlLabel
            value="start"
            control={<Switch color="primary" checked={this.state.charityEnabled ? true : false} />}
            label="Allocate 1% of my income to charity"
            labelPlacement="start"
            onChange={this.toggleCharity}
          />
        </div>
        {'consultant' === this.context.user.type &&
        <>
        <h6 className="fs-root pl-2 mb-2 mt-4">Set your available times</h6>
          <div className="workingHoursCalendar mt-2">
            
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              label="date picker"
              value={this.state.date}
              onChange={(newValue) => {
                this.setState({ date: newValue });
              }}
              minDate={dayjs()}
              maxDate={dayjs().add(1, 'y')}
              renderDay={(date, selectedDates, DayProps) => {
                if (dayjs(date).isToday()) {
                  return <PickersDay {...DayProps} className="today" />
                }
                if (dayjs(date).isBefore(dayjs())) {
                  return <PickersDay {...DayProps} disabled />
                }
                if (!dayjs().isSame(date) && Object.keys(this.state.workingHours).some(d => dayjs(d).isSame(date))) {
                  return <PickersDay {...DayProps} className='selected' />
                }
                return <PickersDay {...DayProps} />
              }}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="'Week of' MMM d"
            />

            <div className="hourSelector">
              <div className="selectedDate mb-3">
                {this.days[dayjs(this.state.date).format('d')]}&nbsp;
              {dayjs(this.state.date).format('MMMM D, YYYY')}
              </div>

              <div className="workingHoursCalendar__hours">
                {this.parseHours().map((time, i) => {
                  let t = workingTimes.find(t => t.time === time)
                  let timeIsPast = dayjs(
                    `${dayjs(this.state.date).format('YYYY-MM-DD')} ${time}`
                  ).isBefore(dayjs(), 'minutes')

                  return (
                    <React.Fragment key={i}>
                      {workingTimes.find(t => t.time === time) ?
                        <div className={`timeWrapper d-flex active ${timeIsPast ? 'past' : ''}`} key={i} onClick={e => { this.toggleWorkingHour(e, t) }}>
                          <div className="time">
                            {t.time}
                          </div>
                          <div className="weeklyCheckWrapper">
                            Weekly
                            <Checkbox
                              checked={t.weekly}
                              inputProps={{ 'aria-label': 'weekly' }}
                              icon={<CheckIcon color="white" size={18} outline />}
                              checkedIcon={<CheckIcon color="white" size={18} />}
                              onChange={e => { this.toggleWeekly(e, t) }}
                            />
                          </div>
                        </div>
                        :
                        <div
                          className={`timeWrapper ${timeIsPast ? 'past' : ''}`}
                          key={i}
                          onClick={e => { this.toggleWorkingHour(e, time) }}>
                          {time}
                        </div>
                      }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
        </>
        }
      </div>
    );
  }
}

export default Settings;