
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';
import AppointmentItem from '../../components/AppointmentItem/AppointmentItem';
import GConnect from '../../components/GConnect/GConnect';
import './Appointments.scss';

class Appointments extends Component {

  state = {
    user: {},
    appointments: [],
    appointmentsHistory: [],
  }

  componentDidMount() {
    document.title = "BankWell | Appointments";
    this.getAppointments();
    this.getAppointmentsHistory();
  }

  getAppointments() {
    axiosInstance.get('/appointments').then(response => {
      this.setState({ appointments: response.data.data })
    }).catch(err => { })
  }

  getAppointmentsHistory() {
    axiosInstance.get('/appointments/history').then(response => {
      this.setState({ appointmentsHistory: response.data.data })
    }).catch(err => { })
  }

  cancelAppointment = id => {
    axiosInstance.patch(`appointments/${id}/cancel`).then(response => {
      toast.success('Successfully cancelled');
      this.getAppointments()
      this.getAppointmentsHistory();
      this.context.getAuthUser()
    }).catch(err => { })
  }

  render() {
    const { appointments, appointmentsHistory } = this.state;

    return (
      <div className="Appointments">
        
        <GConnect/>

        {appointments.length > 0 &&
          <>
            <h6 className="fg-primary fw-bold b-b-regular pb-2">Scheduled</h6>
            {appointments.map(appointment =>
              <AppointmentItem
                appointment={appointment}
                onCancel={() => this.cancelAppointment(appointment._id)}
                key={appointment._id} />
            )}
          </>
        }
        {appointmentsHistory.length > 0 &&
          <>
            <h6 className="mt-4 fg-primary fw-bold b-b-regular pb-2">Past and canceled</h6>
            {appointmentsHistory.map(appointment =>
              <AppointmentItem isArchive appointment={appointment} key={appointment._id} />
            )}
          </>
        }

        {appointments.length === 0 && appointmentsHistory.length === 0 &&
          <h6 className="text-secondary">There is no appointments yet.</h6>
        }
      </div>
    );
  }
}

export default Appointments;