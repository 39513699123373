const CloseIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={className} viewBox="0 0 94.93 94.93" >
        <path d="M55.93 47.46 94.31 9.1c.82-.83.82-2.17 0-3L88.83.63a2.12 2.12 0 0 0-3 0L47.47 38.99 9.1.62c-.8-.8-2.2-.8-3 0L.62 6.1a2.12 2.12 0 0 0 0 2.99L39 47.46.62 85.84a2.12 2.12 0 0 0 0 2.99l5.47 5.48a2.12 2.12 0 0 0 3 0l38.37-38.38 38.38 38.38a2.12 2.12 0 0 0 3 0l5.47-5.48c.82-.83.82-2.17 0-3L55.93 47.47z" fill={color || '#333333'}/>
      </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={className} viewBox="0 0 94.93 94.93" >
        <path d="M55.93 47.46 94.31 9.1c.82-.83.82-2.17 0-3L88.83.63a2.12 2.12 0 0 0-3 0L47.47 38.99 9.1.62c-.8-.8-2.2-.8-3 0L.62 6.1a2.12 2.12 0 0 0 0 2.99L39 47.46.62 85.84a2.12 2.12 0 0 0 0 2.99l5.47 5.48a2.12 2.12 0 0 0 3 0l38.37-38.38 38.38 38.38a2.12 2.12 0 0 0 3 0l5.47-5.48c.82-.83.82-2.17 0-3L55.93 47.47z" fill={color || '#333333'}/>
      </svg>
      }
    </>
  );
};

export default CloseIcon;