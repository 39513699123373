const RefreshIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.7 625.8" >
          <path d="M421 303a173 173 0 00-183-169l5 64c0 3-4 6-8 4L97 114c-3-1-3-5-1-6L221 1c3-2 8-1 8 3l4 64a239 239 0 01192 399l-57-36c33-33 54-78 53-128zM253 558A239 239 0 0162 159l57 36a173 173 0 00-34 202c30 60 93 99 164 95l-5-64c0-4 4-6 7-4l139 88c2 1 3 4 1 6L265 625c-2 2-7 0-7-3z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.7 625.8" >
          <path d="M421 303a173 173 0 00-183-169l5 64c0 3-4 6-8 4L97 114c-3-1-3-5-1-6L221 1c3-2 8-1 8 3l4 64a239 239 0 01192 399l-57-36c33-33 54-78 53-128zM253 558A239 239 0 0162 159l57 36a173 173 0 00-34 202c30 60 93 99 164 95l-5-64c0-4 4-6 7-4l139 88c2 1 3 4 1 6L265 625c-2 2-7 0-7-3z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default RefreshIcon;