import React from 'react';
import GoogleLogin from 'react-google-login';
import { axiosInstance } from '../../axiosConfig';

const GConnect = ({ onSuccess, onFailure, onDisconnect }) => {

  const [connected, setConnected] = React.useState(null);

  React.useEffect(() => {
    getGoogleConnectionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const responseGoogle = response => {
    axiosInstance.post(`/account/google/verifyCode`, {
      code: response.code
    }).then(() => {
      setConnected(true);
      onSuccess && onSuccess();
    }).catch(err => { console.log(err) })
  }

  const getGoogleConnectionStatus = () => {
    axiosInstance.get(`/account/google/connectStatus`).then(() => {
      setConnected(true);
      onSuccess && onSuccess();
    }).catch(err => {
      setConnected(false);
      onFailure && onFailure();
    })
  }

  return (
    <>
      {false === connected &&
        <div 
        className="g-connect p-4"
        style={{
          background: 'var(--colors-green)',
          borderRadius: '4px',
          color: 'rgb(18, 19, 18)',
          marginBottom: '2rem',
        }}
        >
          <div className="container">
            <div className="row flex-center">
              <div className="column col-xs-12 col-md-8">
                <h3 className="m-0">Google Calendar Sync</h3>
                <p>Please connect your account to Google calendar service</p>
              </div>
              <div className="column col-xs-12 col-md-4">
                <GoogleLogin
                  clientId="623624715562-3a73rpg1o3mh3ga533vlr849suuqcqkc.apps.googleusercontent.com"
                  buttonText="Connect to Calendar"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  accessType="offline"
                  responseType="code"
                  prompt="consent"
                  scope="https://www.googleapis.com/auth/calendar.app.created email profile"
                />
              </div>
            </div>
          </div>
        </div>
      }
      {/* {true === this.state.googleConnect &&
          <GoogleLogout
            clientId="1033190642923-h882kvi7rnn8eemukfucsa9h9gjh59j1.apps.googleusercontent.com"
            buttonText="Logout"
            onLogoutSuccess={this.disconnectGoogle}
          >
          </GoogleLogout>} */}

    </>
  );
};

export default GConnect;
