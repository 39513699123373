const CameraIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9941 4.5V1C14.9941 0.45 14.5441 0 13.9941 0H1.99414C1.44414 0 0.994141 0.45 0.994141 1V11C0.994141 11.55 1.44414 12 1.99414 12H13.9941C14.5441 12 14.9941 11.55 14.9941 11V7.5L17.2841 9.79C17.9141 10.42 18.9941 9.97 18.9941 9.08V2.91C18.9941 2.02 17.9141 1.57 17.2841 2.2L14.9941 4.5Z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2V10H2V2H12ZM13 0H1C0.45 0 0 0.45 0 1V11C0 11.55 0.45 12 1 12H13C13.55 12 14 11.55 14 11V7.5L18 11.5V0.5L14 4.5V1C14 0.45 13.55 0 13 0Z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default CameraIcon;