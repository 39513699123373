import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import style from './Footer.module.scss';
import facebook from '../../assets/icons/facebook.svg'
import twitter from '../../assets/icons/twitter.svg'
import linkedin from '../../assets/icons/linkedin.svg'
import instagram from '../../assets/icons/instagram.svg'
import { isMobileOnly as isMobile } from 'react-device-detect';
import { useUserContext } from '../../contexts/UserContext';

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-3 col-md-6 col-lg-4">
            <Link to="/" className={style.logoWrapper}>
              <img src={logo} alt="bankwell" />
              <span className={style.logoType}>BANKWELL</span>
            </Link>
          </div>

          {isMobile ?
            <>
              <div className="d-flex">
                <div className="left">
                  <MyAccount />
                  <About />
                </div>
                <div className="right">
                  <Social />
                </div>
              </div>
            </>
            :
            <>
              <MyAccount />
              <About />
              <Social />
            </>
          }
        </div>
      </div>
    </div>
  );
};

const Social = () => (
  <div className={`${style.widget} col-xs-12 col-sm-4 col-lg-3 mb-4 flex-fill`}>
    <h3 className={`fw-normal ${!isMobile && 'text-center'}`}>Follow Us</h3>
    <div className={style.socialLinks}>
      <a href="http://#fb" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="facebook" />
        <span>Facebook</span>
      </a>
      <a href="http://#tw" target="_blank" rel="noopener noreferrer">
        <img src={twitter} alt="twitter" />
        <span>Twitter</span>
      </a>
      <a href="http://#in" target="_blank" rel="noopener noreferrer">
        <img src={linkedin} alt="linkedin" />
        <span>LinkedIn</span>
      </a>
      <a href="http://fb" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="instagram" />
        <span>Facebook</span>
      </a>
    </div>
  </div>
)

const MyAccount = () => {
  const { isAuthenticated,setAuthPopupOpen } = useUserContext();
  return (
    <div className={`${style.widget} col-xs-12 col-sm-4 col-lg-3 mb-4`}>
      <h3 className="fw-normal">My Account</h3>
      <ul>
        {!isAuthenticated &&
          <>
            <li>
              <Link to="#" onClick={() => setAuthPopupOpen(true)}>Login</Link>
            </li>
            <li>
              <Link to="#" onClick={() => setAuthPopupOpen(true)}>Register</Link>
            </li>
          </>
        }
        {isAuthenticated &&
          <li>
            <Link to="/panel/accountInformation">Account information</Link>
          </li>
        }
      </ul>
    </div>

  )
}

const About = () => (
  <div className={`${style.widget} col-xs-12 col-sm-4 col-lg-2`}>
    <h3 className="fw-normal">About</h3>
    <ul>
      <li>
        <Link to="/">Our Story</Link>
      </li>
      <li>
        <Link to="/">Terms of Use</Link>
      </li>
      <li>
        <Link to="/privacy">Privacy Policy</Link>
      </li>
    </ul>
  </div>

)

export default Footer;