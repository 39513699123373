import React, { Component } from 'react';
import dayjs from 'dayjs';
import UserContext from '../../contexts/UserContext';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { axiosInstance } from '../../axiosConfig';
import toast from 'react-hot-toast';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';

import './ScheduleMeetingDialog.scss';
import { CircularProgress, IconButton } from '@material-ui/core';
import { isBrowser } from 'react-device-detect';
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

class ScheduleMeetingDialog extends Component {

  static contextType = UserContext;
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  state = {
    user: {},
    selectedWorkingHour: null,
    workingHours: null,
    date: new Date(),
    dateFrom: new Date(),
    loading: true,
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.open && false === prevProps.open &&
      this.props.user
      // this.state.workingHours === null
    ) {
      this.getAvailableTimes();
    }
  }

  getAvailableTimes = () => {
    axiosInstance.get(`/working-hours/${this.props.user.id}`).then(response => {
      if (Object.keys(response.data).length) {
        this.setState({
          workingHours: response.data,
          date: Object.keys(response.data)[0],
          dateFrom: Object.keys(response.data)[0],
          loading: false
        })
      } else {
        this.setState({ loading: false })
      }
    }).catch(err => { this.setState({ loading: false }) })
  }

  parseWeeks() {
    let startDate = dayjs(this.state.dateFrom).format('YYYY-MM-D')
    let endDate = dayjs(startDate).add(6, 'day').format('YYYY-MM-D')
    let days = [startDate];
    let day = startDate;
    while (dayjs(day).isBefore(endDate)) {
      day = dayjs(day).add(1, 'day');
      days.push(day.format('YYYY-MM-D'))
    }

    return days;
  }

  nextWeek = () => {
    this.setState({ dateFrom: dayjs(this.state.dateFrom).add(7, 'days') })
  }

  prevWeek = () => {
    let prev = dayjs(this.state.dateFrom).subtract(7, 'days').format('YYYY-MM-D');
    this.setState({ dateFrom: prev })
  }

  nextMonth = () => {
    this.setState({ dateFrom: dayjs(this.state.dateFrom).add(1, 'month') })
  }

  prevMonth = () => {
    let prev = dayjs(this.state.dateFrom).subtract(1, 'month').format('YYYY-MM-D');
    this.setState({ dateFrom: prev })
  }

  parseHours() {
    let hours = []
    for (let h = 6; h < 21; h++) {
      for (let m = 0; m < 60; m += 15) {
        hours.push(`${h}:${m.toString().padStart(2, '0')}`)
      }
    }
    return hours;
  }

  getDateWorkingHours(date) {
    if (!this.state.workingHours) return {};
    date = date || this.state.date;
    let dateWorkingHours = this.state.workingHours[dayjs(date).format('YYYY-MM-D')];
    let workingTimes = [];

    if (dateWorkingHours) {
      dateWorkingHours.forEach((workingHour, i) => {
        workingTimes[i] = {
          id: workingHour._id,
          start: dayjs(workingHour.start).format('H:mm'),
          end: dayjs(workingHour.end).format('H:mm'),
          weekly: workingHour.isWeekly ? true : false,
          isOccupied: workingHour.isOccupied ? true : false,
        }
      })
    }
    return workingTimes;
  }

  errors = response => {
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all required fields')
    }
  }

  setAppointment = () => {
    axiosInstance.post('appointments/', {
      workingHour: this.state.selectedWorkingHour
    }).then(response => {
      this.props.onSelect(response)
    }).catch(err => {
      this.errors(err.response)
    })
  }

  render() {
    const workingTimes = this.getDateWorkingHours();

    let prevMonthDisabled = true;
    let nextMonthDisabled = true;
    let prevWeekDisabled = true;

    if (this.state.workingHours) {
      prevMonthDisabled = dayjs(this.state.dateFrom).isSameOrBefore(dayjs(Object.keys(this.state.workingHours)[0]), 'month');
      nextMonthDisabled = false;
      prevWeekDisabled = dayjs(this.state.dateFrom).isSameOrBefore(dayjs(Object.keys(this.state.workingHours)[0]), 'day');
      // let nextWeekDisabled = false;
    }

    return (
      <div className="ScheduleMeetingDialogWrapper">
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          maxWidth='100%'
          PaperProps={{ className: isBrowser && 'p-4' }}
          className="ScheduleMeetingDialog centered"
          fullScreen={!isBrowser}
        >
          <DialogTitle>Schedule an online meeting time</DialogTitle>
          {this.state.loading ?
            <CircularProgress style={{ margin: '0 auto 2rem' }} />
            : <>
              {this.state.workingHours
                ? <DialogContent>
                  <div className="currentDate d-flex flex-center mb-2">
                    <IconButton
                      disabled={prevMonthDisabled}
                      onClick={this.prevMonth}>
                      <ArrowLeftIcon size={14} color={prevMonthDisabled ? '#aaa' : '#333'} />
                    </IconButton>
                    {dayjs(this.state.dateFrom).format('MMM YYYY')}
                    <IconButton
                      disabled={nextMonthDisabled}
                      onClick={this.nextMonth} >
                      <ArrowRightIcon size={14} color={nextMonthDisabled ? '#aaa' : '#333'} />
                    </IconButton>
                  </div>
                  <div className={`weekWrapper d-flex mt-2 week`}>
                 
                      <Button
                        variant="contained"
                        onClick={this.prevWeek}
                        className={`prev`}
                        disabled={prevWeekDisabled}
                      ><ArrowLeftIcon size={14} color={prevWeekDisabled ? '#aaa' : '#333'} />
                      </Button>
                    
                    {this.parseWeeks().map(day => {
                      let times = this.getDateWorkingHours(day);
                      let allReserved = times.length > 0 && times.every(x => true === x.isOccupied)
                      return (
                        <Button
                          variant={`${dayjs(day).isSame(dayjs(this.state.date), 'day') ? 'contained' : ''}`}
                          className={`day ${allReserved ? 'allReserved' : ''}`}
                          key={day}
                          disabled={!Object.keys(this.state.workingHours).includes(dayjs(day).format('YYYY-MM-D'))}
                          onClick={() => this.setState({ date: dayjs(day) })}
                        >
                          <span >{this.days[dayjs(day).format('d')]}</span>
                          <span >{dayjs(day).format('D')}</span>
                        </Button>
                      )
                    })}
                   
                      <Button
                        variant="contained"
                        onClick={this.nextWeek}
                        className={`next`}>
                        <ArrowRightIcon size={14} />
                      </Button>
                  
                  </div>

                  <div className="hourSelector">
                    <div className="selectedDate mb-3 mt-4">
                      <b>Pick a time</b>&nbsp;
                      <span className="text-tertiary"> ( {dayjs(this.state.date).format('MMMM D, YYYY')}) </span>
                    </div>
                    <div className="workingHoursCalendar__hours">
                      {workingTimes.map((time, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Button
                              className={`time ${time.isOccupied ? 'occupied' : ''}`}
                              key={i}
                              variant={time.id === this.state.selectedWorkingHour ? 'contained' : 'outlined'}
                              onClick={() => { this.setState({ selectedWorkingHour: time.id }) }}
                            >
                              {time.start} - {time.end}
                            </Button>
                          </React.Fragment>
                        )
                      })}
                    </div>

                  </div>
                </DialogContent>
                : <p style={{ margin: '0 auto 1rem' }}>This user does not have time to visit at this time.</p>}

              {this.state.workingHours ?
                <DialogActions>
                  <Button
                    onClick={this.setAppointment}
                    style={{
                      borderRadius:'30px',
                      padding:'.4rem 3rem'
                    }}
                    disableElevation
                    color="primary"
                    variant="contained"
                    disabled={!this.state.selectedWorkingHour}
                  >
                    Next
              </Button>
                </DialogActions>
                : <DialogActions>
                  <Button onClick={this.props.onClose} color="primary"> Ok </Button>
                </DialogActions>
              }
            </>}
        </Dialog>
      </div>
    );
  }
}

export default ScheduleMeetingDialog;