import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormGroup, IconButton, Tooltip } from '@material-ui/core';
import UserContext from '../../contexts/UserContext';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';
import AddMedicalRecordDialog from '../../components/AddMedicalRecordDialog/AddMedicalRecordDialog';
import TextfieldWithCounter from '../../components/TextfieldWithCounter/TextfieldWithCounter';
import { getDisplayName } from '../../utils/user';
import { bytesToSize, withCdnUrl } from '../../utils/common';
import uploadIcon from '../../assets/icons/upload.svg';
import messageIcon from '../../assets/icons/message.svg';
import mp4Icon from '../../assets/icons/mp4.png';
import pdfIcon from '../../assets/icons/pdf.png';
import cloudIcon from '../../assets/icons/download.svg';
import otherFilesIcon from '../../assets/icons/file-other.svg';
import './MedicalRecord.scss';

class MedicalRecord extends Component {

  static contextType = UserContext;
  id = this.props.match.params.recordId;

  state = {
    medicalRecords: [],
    addRecordDialogOpen: false,
    addTextRecordDialogOpen: false,
    recordText: '',
    title: '',
    owner: null,
    addedBy: null,
  }

  componentDidMount() {
    document.title = `BankWell | Medical record details`;
    this.getMedicalRecord()
  }

  handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = {
        ...this.state,
        [e.target.name]: e.target.checked,
        //touchedFields: [ ...this.state.touchedFields, e.target.name ]
      };
      this.setState(newState);
    } else {
      newState = {
        ...this.state,
        [e.target.name]: e.target.value,
        //touchedFields: [ ...this.state.touchedFields, e.target.name]
      }
      this.setState(newState);
    }
  }

  validateForm = e => {
    let newState = {
      ...this.state,
      touchedFields: [...this.state.touchedFields, e.target.name, e.target.name]
    };
    this.setState(newState);

    let errors = {}

    // remove errors for non-touched field
    Object.keys(errors).forEach(err => {
      if (!newState.touchedFields.includes(err)) {
        delete errors[err]
      }
    })

    this.setState({ ...newState, errors })
  }

  errors = response => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all required fields')
    }
    if (!response.data.code && 400 === response.status) {
      toast.error('Form fields are not valid')
    }
  }

  getMedicalRecord = () => {
    let userId = this.props.match.params.userId || this.context.user.id;
    axiosInstance.get(`/users/${userId}/medical-records/${this.id}`).then(response => {
      this.setState({
        medicalRecords: response.data.records,
        title: response.data.title,
        addedBy: response.data.addedBy,
        owner: response.data.owner,
      })
      document.title = `BankWell | ${response.data.title || 'Medical record details'}`;
    }).catch(err => {

    })
  }

  onMedicalRecordAdd = () => {
    this.setState({ addRecordDialogOpen: false });
    this.getMedicalRecord()
  }

  addTextRecord = e => {
    this.setState({ inProgress: true })
    let formData = new FormData();
    // formData.append('recordId', this.id);
    formData.append('text', this.state.recordText);
    formData.append('type', 'text');

    axiosInstance.post(`/users/${this.state.owner.id}/medical-records/${this.id}`, formData).then(response => {
      this.setState({ recordText: '', addTextRecordDialogOpen: false });
      this.getMedicalRecord()
    }).catch(() => {
      toast.error('Error adding medical record')
    }).finally(() => {
      this.setState({ inProgress: false })
    })
  }

  render() {
    let state = this.state;

    return (
      <div className="MedicalRecord">
        <header className="d-flex flex-center flex-space-between mb-3">
          <h6 className="fg-primary">
            {this.state.addedBy && 'consultant' === this.state.addedBy.type
              ? <>{state.title} by {getDisplayName(state.addedBy)}, {state.addedBy.specialties[0]}</>
              : state.title
            }
          </h6>

          {(this.state.addedBy && this.state.addedBy.id === this.context.user.id) &&
            <div className="actions d-flex">
              <Tooltip title="Upload document or video">
                <IconButton
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  color="primary"
                  aria-label="upload document"
                  onClick={() => this.setState({ addRecordDialogOpen: true })}
                >
                  <img src={uploadIcon} alt="upload" width={24} height={24} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add text">
                <IconButton
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  color="primary"
                  aria-label="add text"
                  onClick={() => this.setState({ addTextRecordDialogOpen: true })}
                >
                  <img src={messageIcon} alt="upload" width={16} height={16} />
                </IconButton>
              </Tooltip>
            </div>
          }
        </header>

        <AddMedicalRecordDialog
          open={state.addRecordDialogOpen}
          user={this.state.owner || {}}
          record={this.id}
          onClose={() => { this.setState({ addRecordDialogOpen: false }) }}
          onSuccessfulSave={response => this.onMedicalRecordAdd(response)}
        />

        <Dialog open={state.addTextRecordDialogOpen} onClose={() => this.setState({ addTextRecordDialogOpen: false })}>
          <DialogTitle className="d-flex flex-center">Title</DialogTitle>
          <DialogContent>
            <FormGroup className="mt-2">
              <TextfieldWithCounter
                value={state.recordText}
                onChange={this.handleChange}
                required
                margin="dense"
                id="title"
                label="Title"
                name="recordText"
                placeholder="Enter description"
                type="text"
                fullWidth
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
                inputProps={{ maxLength: 1000 }}
                variant="outlined"
              />
            </FormGroup>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ addTextRecordDialogOpen: false })}>Cancel</Button>
            <Button
              disabled={state.recordText.length < 1 || state.inProgress}
              onClick={this.addTextRecord}
            >
              {state.inProgress ?
                <>
                  <CircularProgress color="secondary" style={{ width: '16px', height: '16px' }} className="mr-2" />
                  Please Wait
                </>
                : <>Save</>
              }
            </Button>
          </DialogActions>
        </Dialog>

        {state.medicalRecords.map(record => {
          let fileExt = '';
          let fileIcon = '';
          if ('file' === record.type) {
            fileExt = record.data.split('.').pop();

            switch (fileExt) {
              case 'mp4':
                fileIcon = mp4Icon;
                break;
              case 'pdf':
                fileIcon = pdfIcon;
                break
              default:
                fileIcon = otherFilesIcon;
            }
          }
          return (
            <div className={`record ${record.type}`} key={record._id}>
              {'text' === record.type &&
                <p className="record__content d-flex flex-center pb-3">
                  {record.data}
                </p>
              }
              {'file' === record.type &&
                <>
                  <a href={withCdnUrl(record.data)} download className="info d-flex flex-fill text-primary td-none pt-3 pb-3">
                    <img src={fileIcon} alt={fileExt} width="50px" height="50px" />
                    <div className="file ml-2">
                      <h6 className="fs-p2 m-0">{record.title}</h6>
                      <div className="size">{bytesToSize(record.fileSize)}</div>
                    </div>
                  </a>
                </>
              }
              <div className="meta d-flex flex-center">
                <div className="time text-secondary">
                  {dayjs(record.createdAt).format('MMMM, D YYYY')} &nbsp;&nbsp;
                  {dayjs(record.createdAt).format('H:mm')}
                </div>
                {'file' === record.type &&
                  <a href={withCdnUrl(record.data)} target="_blank" rel="noopener noreferrer" className=" d-flex ml-2">
                    <img src={cloudIcon} alt="Download" />
                  </a>
                }
              </div>
            </div>
          )
        })}
      </div>
    );
  }
}

export default MedicalRecord;