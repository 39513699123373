import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { axiosInstance } from '../../axiosConfig';

const CountrySelectDropdown = props => {

  const [countries, setCountries] = React.useState([])

  React.useEffect(() => {
    axiosInstance.get('/countries').then(response => {
      setCountries(response.data)
    }).catch(err => { })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormControl
      required variant="standard"
      style={{ width: '100%' }}
      error={props.error ? true : false}>
      <InputLabel id="country">Country</InputLabel>
      <Select
        required
        labelId="country"
        id="country"
        name="country"
        value={props.value}
        onChange={props.onChange}
      >
        <MenuItem value="-1" disabled>
          <span className="text-tertiary"> Tap to select </span>
        </MenuItem>
        {countries.map(country =>
          <MenuItem key={country._id} value={country._id}>{country.name}</MenuItem>
        )}
      </Select>
      {props.error &&
        <FormHelperText>{props.error}</FormHelperText>
      }
    </FormControl>
  )
}

export default CountrySelectDropdown;