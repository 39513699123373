import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink, withRouter } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import CardIcon from '../icons/CardIcon';
import DateIcon from '../icons/DateIcon';
import GearIcon from '../icons/GearIcon';
import PowerIcon from '../icons/PowerIcon';
import UserIcon from '../icons/UserIcon';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

import './UserMenu.scss';
import FilesIcon from '../icons/FilesIcon';
import HomeIcon from '../icons/HomeIcon';
import { Badge } from '@material-ui/core';

const UserMenu = props => {
  const { logout, user } = useUserContext();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeMenu = () => {
    props.onClose && props.onClose()
  }

  const doLogout = () => {
    logout();
    closeMenu();
    props.history.push('/')
  }

  return (
    <div className="userMenu">
      {props.showHome &&
        <MenuItem
          component={NavLink}
          to="/"
          onClick={closeMenu}
          activeClassName="non-active"
        ><HomeIcon size={26} /> Home </MenuItem>
      }
      {'employer' === user.type &&
        <MenuItem
          component={NavLink}
          to="/panel/employees"
          onClick={closeMenu}
          activeClassName="active"
        ><DateIcon /> Employees </MenuItem>
      }

      {['consultant', 'freelancer', 'employee'].includes(user.type) &&
        <MenuItem
          component={NavLink}
          to="/panel/appointments"
          onClick={closeMenu}
          activeClassName="active"
        ><DateIcon /> Appointments </MenuItem>
      }

      <MenuItem
        component={NavLink}
        to="/panel/transactions"
        onClick={closeMenu}
        activeClassName="active"
      > <CardIcon />Transactions </MenuItem>

      {['employee', 'freelancer'].includes(user.type) &&
        <Badge
          invisible={!user.questionnaires || Object.values(user.questionnaires).filter(x => x !== true).length === 0}
          badgeContent={1}
          variant="dot"
          color="warning"
          style={{ width: '100%' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            style={{ width: '100%' }}
            component={NavLink}
            to="/panel/medical-records"
            onClick={closeMenu}
            activeClassName="active"
          ><FilesIcon />Medical records</MenuItem>
        </Badge>
      }

      <MenuItem
        component={NavLink}
        to="/panel/accountInformation"
        onClick={closeMenu}
        activeClassName="active"
      ><UserIcon />Account information </MenuItem>

      {['consultant', 'employer'].includes(user.type) &&
        <MenuItem
          component={NavLink}
          to="/panel/settings"
          onClick={closeMenu}
          activeClassName="active"
        > <GearIcon />Settings </MenuItem>
      }

      <MenuItem
        onClick={handleClickOpen}
      ><PowerIcon />Sign Out</MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sign Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to leave?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={doLogout} autoFocus>
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(UserMenu);