import Button from '@material-ui/core/Button';
import React from 'react';
import style from './Pagination.module.scss';

const Pagination = ({ page, total, pageSize, onPageChange, className, resetScroll = false }) => {

  page = page || 1;

  const onNext = () => {
    onPageChange(page + 1);
    resetScroll && window.scrollTo(0, 0);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
    resetScroll && window.scrollTo(0, 0);
  };

  let start = (page - 1) * pageSize;
  start > 1 && start++
  start = start || 1;

  let end = page * pageSize;
  if (end > total) end = total;

  let classes = [
    style.pagination,
    className || ''
  ].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      <Button disabled={page < 2} onClick={onPrevious}>Prev</Button>
      <span className={pageSize >= total ? 'text-tertiary' : ''}>
        {start} to {end} of {total}
      </span>
      <Button disabled={page * pageSize >= total} onClick={onNext}>Next</Button>
    </div>
  );
};

export default Pagination;