import React, { Component } from 'react';
import dayjs from 'dayjs';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { axiosInstance } from '../../axiosConfig';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

import { formatPrice, withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import style from './Therapist.module.scss';
import UserReview from '../../components/UserReview/UserReview';
import Pagination from '../../components/Pagination/Pagination';

import privacy from '../../assets/images/privacy.png';
import approved from '../../assets/images/approved.png';
import refund from '../../assets/images/refund.png';
import AddTherapistReviewDialog from '../../components/AddTherapistReviewDialog/AddTherapistReviewDialog';
import UserContext from '../../contexts/UserContext';
import MeetingButton from '../../components/MeetingButton/MeetingButton';
import { isBrowser } from 'react-device-detect';
import { Rating } from '@material-ui/core';

import ArrowLeftIcon from '../../components/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../components/icons/ArrowRightIcon';

class Therapist extends Component {

  static contextType = UserContext;

  id = this.props.match.params.userId;
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  state = {
    user: {},
    reviews: [],
    scores: {},
    total: null,
    page: 1,
    pageSize: 5,
    specialty: -1,
    reviewDialogOpen: false,
    workingHours: null,
    selectedWorkingHour: null,
    date: new Date(),
    dateFrom: new Date(),
    loading: true,
  }

  componentDidMount() {
    document.title = "BankWell | Therapist";
    this.getUser();
    this.getAvailableTimes();
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.getReviews()
      })
    }
  }

  getUser() {
    axiosInstance.get(`/users/${this.id}`).then(response => {
      this.setState({ user: response.data })
      document.title = `BankWell | ${getDisplayName(this.state.user)}`;
      this.getReviews()
    }).catch(err => { })
  }

  getReviews() {
    axiosInstance.get(`/reviews/${this.id}?size=${this.state.pageSize}&page=${this.state.page}`).then(response => {
      this.setState({
        reviews: response.data.data,
        total: response.data.total,
        page: response.data.page,
        scores: response.data.scores
      })
    }).catch(err => { })
  }

  getAvailableTimes = () => {
    axiosInstance.get(`/working-hours/${this.id}`).then(response => {

      this.setState({
        workingHours: response.data,
        date: Object.keys(response.data)[0],
        dateFrom: Object.keys(response.data)[0],
        loading: false
      })

    }).catch(err => { this.setState({ loading: false }) })
  }

  parseWeeks() {
    let startDate = dayjs(this.state.dateFrom).format('YYYY-MM-D')
    let endDate = dayjs(startDate).add(6, 'days').format('YYYY-MM-D')
    let days = [startDate];
    let day = startDate;
    while (dayjs(day).isBefore(endDate)) {
      day = dayjs(day).add(1, 'day');
      days.push(day.format('YYYY-MM-D'))
    }

    return days;
  }

  nextWeek = () => {
    this.setState({ dateFrom: dayjs(this.state.dateFrom).add(7, 'days') })
  }

  prevWeek = () => {
    let prev = dayjs(this.state.dateFrom).subtract(7, 'days').format('YYYY-MM-D');
    this.setState({ dateFrom: prev })
  }

  parseHours() {
    let hours = []
    for (let h = 6; h < 21; h++) {
      for (let m = 0; m < 60; m += 15) {
        hours.push(`${h}:${m.toString().padStart(2, '0')}`)
      }
    }
    return hours;
  }

  getDateWorkingHours(date) {
    if (!this.state.workingHours) return {};
    date = date || this.state.date;
    let dateWorkingHours = this.state.workingHours[dayjs(date).format('YYYY-MM-D')];
    let workingTimes = [];

    if (dateWorkingHours) {
      dateWorkingHours.forEach((workingHour, i) => {
        workingTimes[i] = {
          id: workingHour._id,
          start: dayjs(workingHour.start).format('H:mm'),
          end: dayjs(workingHour.end).format('H:mm'),
          weekly: workingHour.isWeekly ? true : false,
          isOccupied: workingHour.isOccupied ? true : false,
        }
      })
    }
    return workingTimes;
  }


  render() {
    const { user } = this.state;
    let prevWeekDisabled = true;
    if (this.state.workingHours) {
      prevWeekDisabled = dayjs(this.state.dateFrom).isSameOrBefore(dayjs(Object.keys(this.state.workingHours)[0]), 'day');
      // let nextWeekDisabled = false;
    }

    const UserInfo = props => (
      <div {...props}>
        <Avatar
          style={{ width: '200px', height: '200px', margin: '0 auto' }}
          src={withCdnUrl(user.photo)}
          alt={getDisplayName(user)}
        />
        <h3 className={style.name}>{getDisplayName(user)}</h3>
        <h4 className={style.specialty}>{user.specialties && user.specialties[0]}</h4>
        <Rating
          readOnly
          className="flex-c-center"
          precision={.5}
          style={{ color: 'var(--primary-color)' }}
          value={user.rating || 0}
        />
      </div>
    )

    return (
      <div className="Therapist">
        <Header backButton={true} />
        <div className={`container content ${style.page}`}>
          {isBrowser &&
            <aside className={style.sidebar}>
              <UserInfo className="d-flex flex-column text-center" />
            </aside>
          }

          <main className={style.main}>
            <div className="d-flex flex-space-between flex-center">
              {!isBrowser && <UserInfo className="d-flex flex-column text-center flex-fill mb-3" />}
              {isBrowser && <h2>About Me</h2>}
              {user.id && this.context.user.id !== user.id && ['freelancer', 'employee'].includes(this.context.user.type) &&
                <div className={style.btnAddReview}>
                  <Button
                    color="primary"
                    onClick={() => this.setState({ reviewDialogOpen: true })}
                  >Rate</Button>
                  <AddTherapistReviewDialog
                    open={this.state.reviewDialogOpen}
                    user={user}
                    onAdd={() => {
                      this.setState({ reviewDialogOpen: false })
                    }}
                    onClose={() => this.setState({ reviewDialogOpen: false })}
                  />
                </div>
              }
            </div>
            <p className={`${style.about} ${!isBrowser && 'text-center'}`}>{user.bio}</p>
            {((!this.context.isAuthenticated && user.sessionPrice > 0)|| (user.sessionPrice > 0 && this.context.user.id !== user.id && ['freelancer', 'employee'].includes(this.context.user.type))) &&
              <div className={style.actions}>
                <MeetingButton user={user} buttonLabel="Meet" />
                <span className={style.cost}>45 Min/ {formatPrice(user.sessionPrice)}</span>
              </div>
            }
          </main>
        </div>
        <div className={style.availableTime}>
          <h3 className="letter-spacing-low mt-0 mb-3">Available Times</h3>

          <div className={`${style.weekNavigator} weekNavigator mt-0 mb-4 d-flex flex-c-center`}>
            <Button
              variant="contained"
              onClick={this.prevWeek}
              className={`prev`}
              disabled={prevWeekDisabled}
            ><ArrowLeftIcon size={14} color={prevWeekDisabled ? 'rgba(0,0,0,0.2)' : '#fff'} />
            </Button>
            <h4 className="letter-spacing-low m-0">
              {dayjs(this.state.dateFrom).format('MMM D, YYYY')} - {dayjs(this.state.dateFrom).add(7, 'days').format('MMM D, YYYY')}
            </h4>
            <Button
              variant="contained"
              onClick={this.nextWeek}
              className={`next`}>
              <ArrowRightIcon size={14} color="#fff" />
            </Button>
          </div>

          {null !== this.state.workingHours ?
            <div className={`container ${style.daysWrapper}`}>

              {this.parseWeeks().map(day => {
                let times = this.getDateWorkingHours(day);
                return (
                  <div className={style.day} key={day}>
                    <div className={style.dayName}>
                      <span >{this.days[dayjs(day).format('d')]}</span>
                      {/* <span >{dayjs(day).format('D')}</span> */}
                    </div>
                    <ul className={style.times}>
                      {times.length
                        ?
                        <>
                          {times.map((time, i) => {
                            return (
                              <React.Fragment key={i}>
                                <li>{time.start} - {time.end}</li>
                              </React.Fragment>
                            )
                          })}
                        </>
                        : <li>No Time</li>}
                    </ul>
                  </div>

                )
              })}
            </div>
            : <>Loading data ...</>}
        </div>
        <div className={style.reviews}>
          <div className={`container content ${style.page}`}>
            <aside className={`${style.sidebar} ${isBrowser && style.stickySidebar}`}>
              <h5 className="letter-spacing-low mt-4 mb-4">Users Review</h5>
              <div className="factor mb-3">
                <span className="fs-p2 text-secondary"> Professionalism </span>
                <div><Rating
                  className="mt-1 mb-2"
                  precision={.5}
                  style={{ color: 'var(--primary-color)' }}
                  value={this.state.scores.professionalism ? this.state.scores.professionalism : 0}
                  readOnly
                /></div>
              </div>
              <div className="factor mb-3">
                <span className="fs-p2 text-secondary">Diagnosis</span>
                <div><Rating
                  className="mt-1 mb-2"
                  precision={.5}
                  style={{ color: 'var(--primary-color)' }}
                  value={this.state.scores.diagnosis ? this.state.scores.diagnosis : 0}
                  readOnly
                /></div>
              </div>
              <div className="factor mb-3">
                <span className="fs-p2 text-secondary">Behavior</span>
                <div><Rating
                  className="mt-1 mb-2"
                  precision={.5}
                  style={{ color: 'var(--primary-color)' }}
                  value={this.state.scores.behavior ? this.state.scores.behavior : 0}
                  readOnly
                /></div>
              </div>
            </aside>
            <main className={style.main}>
              {0 === this.state.reviews.length &&
                <div className="noDataMessage">
                  <h6 className="title">No reviews</h6>
                  <p className="title">There is no reviews submitted for this user.</p>
                </div>
              }
              {this.state.reviews.map((review, i) =>
                <UserReview
                  key={i}
                  user={review.author}
                  review={review.comment}
                  rating={review.rating}
                  date={review.createdAt}
                />
              )}

              {this.state.total > this.state.pageSize &&
                <Pagination
                  className={style.pagination}
                  page={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.pageSize}
                  onPageChange={this.updatePage}
                />
              }
            </main>
          </div>
        </div>
        <div className={style.availableTime}>
          <div className="container">
            <h3 className="letter-spacing-low mt-0 mb-4 text-uppercase">BankWell Guaranty</h3>
            <div className="row mt-5">
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className={style.feature}>
                  <img src={privacy} alt="privacy" />
                  <h5 className={style.featureTitle}>Keep Your Privacy</h5>
                  <p>Your information and consultation details will be kept confidential.</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className={style.feature}>
                  <img src={approved} alt="add content" />
                  <h5 className={style.featureTitle}>Approved Therapists</h5>
                  <p>All therapists are approved through a rigorous process.</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className={style.feature}>
                  <img src={refund} alt="launch" />
                  <h5 className={style.featureTitle}>Refund</h5>
                  <p>We refund the money in case you are not satisfied by the consulting session.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Therapist;