import dayjs from 'dayjs';
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { axiosInstance } from '../../axiosConfig';
import { getDisplayName } from '../../utils/user';
import { Checkbox, CircularProgress, DialogContentText, FormControlLabel, FormGroup } from '@material-ui/core';
import { formatPrice } from '../../utils/common';
import TextfieldWithCounter from '../TextfieldWithCounter/TextfieldWithCounter';

class ScheduleMeetingConfirmDialog extends Component {
  state = {
    appointment: null,
    comment: '',
    confirm: false,
    policyDialogOpen: false
  }
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  componentDidMount() {
    // if(!this.props.appointment) return;
    axiosInstance.get(`appointments/${this.props.appointment._id}`).then(response => {
      this.setState({
        appointment: response.data
      })
    })
  }

  confirmAppointment = e => {
    axiosInstance.patch(`appointments/${this.props.appointment._id}`, {
      data:{
        comment: this.state.comment,
        reviewed: true,
      }
    }).then(response => {
      this.props.onSubmit(response)
    }).catch(err => {})
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          maxWidth='100%'
          PaperProps={{ className:'pl-4 pr-4 pt-2 pb-2' }}
          className="ScheduleMeetingConfirmDialog centered"
        >
          <DialogTitle>Review meeting details</DialogTitle>
          {this.state.appointment && this.state.appointment.consultant ?
            <DialogContent className="p-5">
              <div className="infoWrapper">
                <div className="info mb-2">
                  Session with <b>{getDisplayName(this.state.appointment.consultant)}, {this.state.appointment.consultant.specialties[0]}</b>
                </div>
                <div className="info mb-2">
                  Date:&nbsp;
                {this.days[dayjs(this.state.appointment.start).format('d')]},&nbsp;
                {dayjs(this.state.appointment.start).format('MMMM DD, YYYY')}
                </div>
                <div className="info mb-2">
                  Time:&nbsp;
                {dayjs(this.state.appointment.start).format('HH:mm')}
                </div>
                <div className="info mb-2">
                  Duration:&nbsp;
                {dayjs(this.state.appointment.end).diff(this.state.appointment.start, 'minutes')} minutes
              </div>
                <div className="info mb-2">
                  Price:&nbsp;
                {formatPrice(this.state.appointment.payment.amount)}
                </div>
              </div>

              <FormGroup className="mt-3">
                <TextfieldWithCounter
                  value={this.state.comment}
                  onChange={e => this.setState({ comment: e.target.value })}
                  // required
                  margin="dense"
                  id="comment"
                  label="Your comment"
                  name="comment"
                  placeholder="Comment..."
                  type="text"
                  fullWidth
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={3}
                  inputProps={{ maxLength: 500 }}
                  variant="outlined"
                />
              </FormGroup>

              <FormControlLabel
                value={this.state.confirm}
                checked={this.state.confirm}
                control={<Checkbox onChange={
                  e => { this.setState({ confirm: e.target.checked }) }
                } />}
                label="I have read and agree to the"
                labelPlacement="end"
              />
              <Button
                onClick={() => this.setState({ policyDialogOpen: true })}
                style={{
                  padding: 0,
                  textTransform: 'inherit',
                  marginLeft: '-10px',
                  verticalAlign: 'middle'
                }}
              >Cancellation Policy</Button>

            </DialogContent>
            : <CircularProgress style={{ margin: '0 auto 2rem' }} />
          }

          {this.state.appointment &&
            <DialogActions>
              <Button
                onClick={this.confirmAppointment}
                style={{
                  borderRadius:'30px',
                  padding:'.4rem 3rem'
                }}
                disableElevation
                color="primary"
                variant="contained"
                disabled={!this.state.confirm}
              >
                Next
              </Button>
            </DialogActions>
          }
        </Dialog>

        <Dialog
          open={this.state.policyDialogOpen}
          onClose={() => this.setState({ policyDialogOpen: false })}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Cancellation Policy</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              {[...new Array(10)]
                .map(
                  () => `Cras mattis consectetur purus sit amet fermentum.
                        Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                )
                .join('\n')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ policyDialogOpen: false })}>Discard</Button>
            <Button onClick={() => this.setState({ policyDialogOpen: false, confirm: true })}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ScheduleMeetingConfirmDialog;