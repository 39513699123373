import React from 'react';

const Star = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.80979 0.58541C4.86966 0.401148 5.13034 0.401148 5.19021 0.58541L6.07767 3.31672C6.10444 3.39912 6.18123 3.45492 6.26788 3.45492H9.13975C9.33349 3.45492 9.41405 3.70284 9.2573 3.81672L6.93391 5.50476C6.86382 5.55569 6.83448 5.64596 6.86126 5.72837L7.74871 8.45967C7.80859 8.64394 7.59769 8.79716 7.44095 8.68328L5.11756 6.99524C5.04746 6.94431 4.95254 6.94431 4.88244 6.99524L2.55905 8.68328C2.40231 8.79716 2.19141 8.64394 2.25129 8.45967L3.13874 5.72837C3.16552 5.64596 3.13618 5.55569 3.06609 5.50476L0.742697 3.81672C0.585954 3.70284 0.66651 3.45492 0.860254 3.45492H3.73212C3.81877 3.45492 3.89556 3.39912 3.92233 3.31672L4.80979 0.58541Z" fill={color} />
  </svg>
);

const Rating = ({ score, fillColor, color, size, className }) => {
  fillColor = fillColor || '#fff';
  color = color || 'rgba(0,0,0,0.25)';

  let classes = [
    'Rating',
    className || ''
  ].filter(Boolean).join(' ');


  return (
    <div className={classes} style={{ display: 'flex' }}>
      <Star size={size || 10} color={score >= 1 ? fillColor : color} />
      <Star size={size || 10} color={score >= 2 ? fillColor : color} />
      <Star size={size || 10} color={score >= 3 ? fillColor : color} />
      <Star size={size || 10} color={score >= 4 ? fillColor : color} />
      <Star size={size || 10} color={score >= 5 ? fillColor : color} />
    </div>
  );
};

export default Rating;