const getDisplayName = function (user) {
  if(!user) return;
  
  if(user.companyName){
    return user.companyName;
  } 

  if(user.firstName && user.lastName){
    return `${user.firstName} ${user.lastName}`;
  } 

  return user.email;
}

const isRegistrationCompleted = user => {
  return !user.registrationUncompleted;
}

const getDisplayPhone = function(number){
  if(!number) return;
  return number.replace('+98', '0')
}

const isValidPhoneNumber = function(number){
  return (/^09[0-9]{9}$|^\+989[0-9]{9}$/).test(number)
}

export{
  getDisplayName,
  getDisplayPhone,
  isValidPhoneNumber,
  isRegistrationCompleted
}