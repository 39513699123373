import React from 'react';
import { isBrowser } from 'react-device-detect';
import { Route, Switch } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import AccountInformation from '../../scenes/AccountInformation/AccountInformation';
import Appointments from '../../scenes/Appointments/Appointments';
import Employees from '../../scenes/Employees/Employees';
import MedicalRecord from '../../scenes/MedicalRecord/MedicalRecord';
import MedicalRecords from '../../scenes/MedicalRecords/MedicalRecords';
import Settings from '../../scenes/Settings/Settings';
import Transactions from '../../scenes/Transactions/Transactions';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import UserSidebar from '../UserSidebar/UserSidebar';
import style from './UserPanel.module.scss';

const UserPanel = () => {
  const { user } = useUserContext();

  return (
    <div className="UserPanel">
      <Header backButton={true} />
      <div className={`container content ${style.page}`}>
        {isBrowser &&
        <aside className={style.sidebar}>
          <UserSidebar />
        </aside>
        }

        <main className={style.main}>
          <Switch>
            {['employee', 'freelancer'].includes(user.type) &&
              <Route path="/panel/medical-records/:recordId" component={MedicalRecord} />
            }
            {['employee', 'freelancer'].includes(user.type) &&
              <Route path="/panel/medical-records" component={MedicalRecords} />
            }

            <Route path="/panel/employees" component={Employees} />
            <Route path="/panel/appointments" component={Appointments} />
            <Route path="/panel/transactions" component={Transactions} />
            <Route path="/panel/accountInformation" component={AccountInformation} />
            <Route path="/panel/settings" component={Settings} />
            <Route component={AccountInformation} />
          </Switch>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default UserPanel;