import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import { withCdnUrl } from '../../utils/common';
import editIcon from '../../assets/icons/edit.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

const AvatarEdit = ({ photo, onChange, size, inProgress }) => {

  let fileUploadInput = React.createRef();

  return (
    <div style={{ position: 'relative', width: `${size}px`, height: `${size}px` }}>
      <Avatar
        src={withCdnUrl(photo)}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
      <IconButton
        disabled={inProgress}
        style={{
          background: !inProgress ? 'var(--primary-color)' : 'lightgray',
          position: 'absolute',
          bottom: '0',
          right: '0',
          padding: '.7rem'
        }}
        onClick={e => { fileUploadInput.click() }}
      >
        {inProgress ?
          <CircularProgress color="secondary" style={{width: `${size / 7}px`, height: `${size / 7}px` }} />
          :
          <img
            style={{ width: `${size / 7}px`, height: `${size / 7}px` }}
            src={editIcon}
            alt="e"
            aria-label="Edit avatar" />
        }
      </IconButton>
      <input ref={f => fileUploadInput = f} className="sr-only" type="file" accept="image/png, image/jpeg" onChange={onChange} />
    </div>
  );
};

export default AvatarEdit;