const ArrowLeftIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.08.5A.83.83 0 005.9.5L.36 6.02a.66.66 0 000 .94l5.54 5.54a.83.83 0 101.18-1.18L2.25 6.5 7.1 1.67A.83.83 0 007.08.49z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.08.5A.83.83 0 005.9.5L.36 6.02a.66.66 0 000 .94l5.54 5.54a.83.83 0 101.18-1.18L2.25 6.5 7.1 1.67A.83.83 0 007.08.49z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default ArrowLeftIcon;