const HomeIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default HomeIcon;