import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';
import { Route, Switch } from 'react-router-dom';
import { axiosInstance } from '../../axiosConfig';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import UserSidebar from '../../components/UserSidebar/UserSidebar';
import UserContext from '../../contexts/UserContext';
import MedicalRecord from '../MedicalRecord/MedicalRecord';
import MedicalRecords from '../MedicalRecords/MedicalRecords';
import style from './User.module.scss';

class User extends Component {
  static contextType = UserContext;

  userId = this.props.match.params.userId;

  state = {
    user: {}
  }

  componentDidMount() {
    axiosInstance.get(`users/${this.userId}`).then(response => {
      this.setState({ user: response.data })
    }).catch(err => { })
  }

  render() {
    return (
      <div className="User">
        <Header backButton={true} />
        <div className={`container content ${style.page}`}>
          {isBrowser &&
            <aside className={style.sidebar}>
              {this.state.user &&
                <UserSidebar user={this.state.user} />
              }
            </aside>
          }

          <main className={style.main}>
            <Switch>
              {['consultant'].includes(this.context.user.type) &&
                <Route path="/user/:userId/medical-records/:recordId" component={MedicalRecord} />
              }
              {['consultant'].includes(this.context.user.type) &&
                <Route path="/user/:userId/medical-records" component={MedicalRecords} />
              }
            </Switch>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
};

export default User;