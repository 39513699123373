import React from 'react';
import { formatPrice, withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import Rating from '@material-ui/core/Rating';
import style from './UserCard.module.scss';
import ShowMoreText from "react-show-more-text";
import { Link } from 'react-router-dom';
import UserIcon from '../icons/UserIcon';
import MeetingButton from '../MeetingButton/MeetingButton';
import { useUserContext } from '../../contexts/UserContext';
import { isBrowser } from 'react-device-detect';
import { Avatar } from '@material-ui/core';

const UserCard = ({ user }) => {
  const { user: authUser, isAuthenticated } = useUserContext()
  return (
    <div className={style.card} key={user.id}>
      <div className={style.avatarWrapper}>
        {isBrowser ?

          <Link to={`/therapist/${user.id}`}>
            {user.photo
              ? <img src={withCdnUrl(user.photo)} alt={getDisplayName(user)} />
              : <div><UserIcon color="rgb(227, 227, 227)" size={50} /></div>
            }
          </Link>
          :
          <Link to={`/therapist/${user.id}`}>
            <Avatar src={withCdnUrl(user.photo)} alt={getDisplayName(user)} />
          </Link>
        }
      </div>
      <div className={style.contentWrapper}>
        <h3 className={style.name}>
          <Link to={`/therapist/${user.id}`}>{getDisplayName(user)} {!isBrowser &&
            <small className="text-tertiary">45 Min/ {formatPrice(user.sessionPrice)}</small>
          }</Link>
        </h3>
        <div className={style.metaWrapper}>
          <h4 className={style.specialty}>{user.specialties[0]}</h4>
          <div className={style.ratingWrapper}>
            {isBrowser ?
              <Rating
                readOnly
                style={{ color: 'var(--primary-color)' }}
                defaultValue={user.rating}
                max={5}
                precision={0.1} />
              :
              <>
                <Rating
                  readOnly
                  size="small"
                  style={{ color: 'var(--primary-color)' }}
                  defaultValue={user.rating / 5}
                  max={1}
                  precision={0.1} />
                <small>{user.rating && user.rating.toFixed(1)}</small>
              </>
            }
            <span className={style.reviews}>
              {user.reviewsCount ?
                <>{user.reviewsCount} Reviews</>
                : <>No Reviews</>
              }
            </span>
          </div>
        </div>
        <div className={style.about}>
          <ShowMoreText
            lines={3}
            more="read more"
            less="show less"
            className="content-css"
            anchorClass="read-more"
            expanded={false}
            truncatedEndingComponent={"... "}
          >{user.bio}</ShowMoreText>
        </div>
      </div>
      {(!isAuthenticated || (user.id !== authUser.id && ['freelancer', 'employee'].includes(authUser.type))) &&
        <div className={style.actions}>
          <MeetingButton user={user} asIconButton={!isBrowser} />
          {isBrowser &&
            <span className={style.cost}>45 Min/ {formatPrice(user.sessionPrice)}</span>
          }
        </div>
      }
    </div>
  );
};

export default UserCard;