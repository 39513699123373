const FilesIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 0.25H3C1.625 0.25 0.5 1.375 0.5 2.75V20.25H3V2.75H18V0.25ZM16.75 5.25H8C6.625 5.25 5.5125 6.375 5.5125 7.75L5.5 25.25C5.5 26.625 6.6125 27.75 7.9875 27.75H21.75C23.125 27.75 24.25 26.625 24.25 25.25V12.75L16.75 5.25ZM8 25.25V7.75H15.5V14H21.75V25.25H8Z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 0.25H3C1.625 0.25 0.5 1.375 0.5 2.75V20.25H3V2.75H18V0.25ZM16.75 5.25H8C6.625 5.25 5.5125 6.375 5.5125 7.75L5.5 25.25C5.5 26.625 6.6125 27.75 7.9875 27.75H21.75C23.125 27.75 24.25 26.625 24.25 25.25V12.75L16.75 5.25ZM8 25.25V7.75H15.5V14H21.75V25.25H8Z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default FilesIcon;