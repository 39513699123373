import {
  BrowserRouter as Router, Route, Switch,

} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import './App.scss';
import ThemeContext from "./contexts/themeContext";
import Home from "./scenes/Home/Home";
import UserContext, { AuthProvider } from "./contexts/UserContext";
import ProfileCompletion from "./scenes/ProfileCompletion/ProfileCompletion";
import { isRegistrationCompleted } from "./utils/user";
import Splash from "./components/Splash/Splash";
import ResetScroll from "./utils/ResetScroll";
import TherapistsList from "./scenes/TherapistsList/TherapistsList";
import Therapist from "./scenes/Therapist/Therapist";
import UserPanel from "./components/UserPanel/UserPanel";
import Search from "./scenes/Search/Search";
import Notifications from "./scenes/Notifications/Notifications";
import User from "./scenes/User/User";
import AuthDialogs from "./components/AuthDialogs/AuthDialogs";
import PrivacyPolicy from "./scenes/PrivacyPolicy/PrivacyPolicy";

function App() {
  return (
    <Router>
      <AuthProvider>
        <ResetScroll>
          <ThemeContext>
            <UserContext.Consumer>
              {({ user, loaded, isAuthenticated }) =>
                loaded ?
                  isRegistrationCompleted(user) ?
                    <>
                      <Switch>
                        {['consultant'].includes(user.type) &&
                          <Route path="/user/:userId" component={User} />
                        }
                        {['consultant'].includes(user.type) &&
                          <Route path="/user/:userId" component={User} />
                        }
                        <Route path="/search" component={Search} />
                        <Route path="/therapists" component={TherapistsList} />
                        <Route path="/therapist/:userId" component={Therapist} />
                        <Route path="/panel" component={isAuthenticated ? UserPanel : Home} />
                        <Route path="/notifications" component={isAuthenticated ? Notifications : Home} />
                        <Route path="/privacy" component={PrivacyPolicy} />
                        <Route path="/" component={Home} />

                      </Switch>
                      <AuthDialogs />
                    </>
                    : <ProfileCompletion />
                  : <Splash />
              }
            </UserContext.Consumer>
            <Toaster position="bottom-center" />
          </ThemeContext>
        </ResetScroll>
      </AuthProvider>
    </Router>
  );
}

export default App;
