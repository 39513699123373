import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import CircularProgress from '@material-ui/core/CircularProgress';

import queryString from 'query-string';
import React, { Component } from 'react';
import ReactShowMoreText from 'react-show-more-text';
import { axiosInstance } from '../../axiosConfig';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Pagination from '../../components/Pagination/Pagination';
import UserCard from '../../components/UserCard/UserCard';
import style from './Search.module.scss';

import searchIcon from '../../assets/icons/search-2.svg';
import toast from 'react-hot-toast';
import { isBrowser } from 'react-device-detect';

class Search extends Component {

  query = queryString.parse(this.props.location.search);

  state = {
    en: queryString.parse(this.props.location.search).l?.split('|')?.includes('en'),
    fr: queryString.parse(this.props.location.search).l?.split('|')?.includes('fr'),
    fa: queryString.parse(this.props.location.search).l?.split('|')?.includes('fa'),
    q: queryString.parse(this.props.location.search).q || '',
    users: [],
    page: 1,
    total: null,
    pageSize: 5,
    loading: false,
  }

  componentDidMount() {
    document.title = "BankWell | Search";
    this.getData();
  }

  setFilter = () => {
    this.query = queryString.parse(this.props.location.search);

    this.query.q = this.state.q

    let langFilter = [
      this.state.fa && 'fa',
      this.state.en && 'en',
      this.state.fr && 'fr'
    ].filter(Boolean).join('|')

    if (langFilter) {
      this.query.l = langFilter;
    } else {
      delete this.query.l
    }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: queryString.stringify(this.query)
    })

    this.getData()
  }

  getData() {
    let q = {
      type: 'consultant',
      size: this.state.pageSize,
      page: this.state.page,
      lang: this.query.l,
      q: this.state.q
    }

    this.setState({ loading: true })
    axiosInstance.get(`/users`, { params: q }).then(response => {
      this.setState({
        users: response.data.data,
        total: response.data.total,
        loading: false
      })
    }).catch(err => { })
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.getData()
      })
    }
  }

  handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = { [e.target.name]: e.target.checked };
      this.setState(newState, () => { this.setFilter() });
    } else {
      newState = { [e.target.name]: e.target.value }
      this.setState(newState);
    }
  }

  handleDateChange = selectedDate => {
    this.setState({ selectedDate });
  };

  doSearch = e => {
    e.preventDefault();

    if (!this.state.q || this.state.q.length > 2) {
      this.setFilter()
    } else {
      toast.error('Please enter at least 3 characters to search')
    }
  }

  render() {
    return (
      <div className="Search">
        <Header backButton={true} />
        <form onSubmit={this.doSearch} className={`searchWrapper ${style.searchWrapper}`}>
          <div className="container">
            {isBrowser &&
            <span className="mr-2">Search:</span>
            }
            <InputBase
              fullWidth
              name="q"
              className={style.input}
              value={this.state.q}
              onChange={this.handleChange}
              placeholder="Enter therapist name ..."
            />
            <IconButton onClick={this.doSearch} className={style.searchButton}><img src={searchIcon} alt="search" aria-label="search" /></IconButton>
          </div>
        </form>

        <div className={`Search container content ${style.page}`}>
          {isBrowser &&
            <aside className={style.sidebar}>
              <div className="widget">
                <h5 className="widget__title">Language</h5>
                <div className="widget__body">
                  <div className="wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.en}
                          onChange={this.handleChange}
                          name="en"
                          color="primary"
                        />
                      }
                      label="English"
                    />
                  </div>
                  <div className="wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fr}
                          onChange={this.handleChange}
                          name="fr"
                          color="primary"
                        />
                      }
                      label="French"
                    />
                  </div>
                  <div className="wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fa}
                          onChange={this.handleChange}
                          name="fa"
                          color="primary"
                        />
                      }
                      label="Persian"
                    />
                  </div>
                </div>
              </div>

              <div className="widget">
                <h5 className="widget__title">What is BankWell</h5>
                <div className="widget__body text-secondary">
                  <ReactShowMoreText
                    lines={4}
                    more="read more"
                    less="show less"
                    className="content-css"
                    anchorClass="read-more"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    <p>Bankwell is the first paramedical benefits bank platform connecting
                      people with the care and services they need for better health,
                      aiming at preventing medical conditions which may occur in
                      their environment and workplace before they reach the point
                      of requiring medical involvement and treatment.
                    </p>
                    <p>The only platform offering a holistic approach to preventive
                      physical and mental health care in the work environment and
                      providing AI insights, predictions and suggestions to employers
                      regarding the problems which may most commonly occur in
                      their type of work environment and recommended
                      preventative measures.
                    </p>
                </ReactShowMoreText>
                </div>
              </div>

              <div className="widget">
                <h5 className="widget__title">FAQ</h5>
                <div className="widget__body text-secondary">
                  <p>
                    How does BankWell differ from other services that claim they give preventional helthcare?
                </p>
                  <p>How do I get matched up?</p>
                  <p>
                    Do I need a test call before starting my ordinary session with a consultant?</p>
                </div>
              </div>
            </aside>
          }
          <main className={style.main}>
            <div className="users">
              <>
                {this.state.loading && <CircularProgress style={{ display: 'block', margin: '2rem auto' }} />}
                {!this.state.loading && this.state.users.length === 0
                  && <h5>Sorry, We cannot found any Therapist at the moment.</h5>
                }

                {!this.state.loading && this.state.users.length > 0 && this.state.users.map(user =>
                  <UserCard user={user} key={user.id} />
                )
                }
              </>
            </div>

            <Pagination
              className={style.pagination}
              page={this.state.page}
              total={this.state.total}
              pageSize={this.state.pageSize}
              onPageChange={this.updatePage}
            />

          </main>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Search;