import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';

import './Header.scss';
import logo from './logo.png';
import { useUserContext } from '../../contexts/UserContext';
import { getDisplayName, isRegistrationCompleted } from '../../utils/user';
import { withCdnUrl } from '../../utils/common';

import backIcon from '../../assets/icons/arrow-left.svg';

import caretDownIcon from '../../assets/icons/caret-down.svg';
import powerOffIcon from '../../assets/icons/power-off.svg';

import UserMenu from '../UserMenu/UserMenu';
import BellIcon from '../icons/BellIcon';
import Badge from '@material-ui/core/Badge';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

const Header = (props) => {
  const { isAuthenticated, user, newNotifications, logout, setAuthPopupOpen } = useUserContext();

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleMenuClick = e => setAnchorEl(e.currentTarget)
  const handleMenuClose = () => {setAnchorEl(null)}
  const openAuthPopup = () => { setAuthPopupOpen(true) }

  const back = () => {
    if (props.history.action !== 'POP') {
      props.history.goBack();
    } else {
      props.history.push('/')
    }
  }

  return (
    <header className={`header ${isMobile ? 'isMobile' : ''}`}>
      <div className="headerInner">
        <div className="d-flex">
          {props.backButton &&
            <button className="backButton" onClick={back}>
              <img src={backIcon} alt="back" />
            </button>
          }
          <Link to="/" className="logoWrapper">
            <img src={logo} alt="bankWell" />
            <span className="logoType">BANKWELL</span>
          </Link>
        </div>
        <div className="userArea">
          {isAuthenticated
            ? <>
              <div className="d-flex flex-center">
              
                <BrowserView>
                  <Button onClick={handleMenuClick} className="userMenuButton">
                    <div className="d-flex flex-fill flex-center">
                      <div className="mr-2">
                        <Avatar alt={getDisplayName()} src={withCdnUrl(user.photo)} style={{ width: '36px', height: '36px' }} />
                      </div>
                      <span className="name">
                        {getDisplayName(user)}
                      </span>
                    </div>
                    <img src={caretDownIcon} alt="menu" />
                  </Button>
                </BrowserView>
                <MobileView>
                  <Button onClick={handleMenuClick} className="userMenuButton" disableRipple>
                    <Avatar alt={getDisplayName()} src={withCdnUrl(user.photo)} style={{ width: '36px', height: '36px' }} />
                  </Button>
                </MobileView>
                
                <Link to="/notifications" className={`d-flex ${isMobile ? 'mr-2' : 'ml-3'}`}>
                  <Badge badgeContent={newNotifications} color="error">
                    <BellIcon size={25} color={isMobile ? '#333' : 'white'} className="" />
                  </Badge>
                </Link>
                {props.actions}
              </div>

              {isRegistrationCompleted(user) ?
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <UserMenu onClose={handleMenuClose} showHome={true}/>
                </Menu>
                :
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => {
                    logout();
                    props.history.push('/')
                  }}> <img src={powerOffIcon} alt="logout" className="icon mr-3" />Sign Out </MenuItem>
                </Menu>}
            </>
            : <Button onClick={openAuthPopup} className="loginButton">Sign in/ Sign up</Button>
          }
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);