const ArrowRightIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M.92 12.5c.33.33.85.33 1.18 0l5.54-5.53a.66.66 0 000-.94L2.1.49A.83.83 0 10.92 1.67L5.75 6.5.9 11.33a.83.83 0 00.01 1.18z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M.92 12.5c.33.33.85.33 1.18 0l5.54-5.53a.66.66 0 000-.94L2.1.49A.83.83 0 10.92 1.67L5.75 6.5.9 11.33a.83.83 0 00.01 1.18z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default ArrowRightIcon;