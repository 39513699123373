import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import dayjs from 'dayjs';
import RatingSimple from '../RatingSimple/RatingSimple';
import { isBrowser } from 'react-device-detect';
// import style from './UserReview.module.scss';

const UserReview = ({ user, review, rating, date }) => {
  return (
    <div className="d-flex pt-4 pb-4" style={{ borderBottom: '1px solid #e9e9e9' }}>
      <div className="avatarHolder">
        {isBrowser ?
          <Avatar
            style={{ width: '90px', height: '90px' }}
            src={withCdnUrl(user.photo)}
            alt={getDisplayName(user)} />
          : <Avatar
            style={{ width: '50px', height: '50px' }}
            src={withCdnUrl(user.photo)}
            alt={getDisplayName(user)} />
        }
      </div>
      <div className="contentHolder full-width ml-3">
        <div className={isBrowser ? 'd-flex flex-space-between flex-center' : 'd-flex flex-column'}>
          <h6>{getDisplayName(user)}</h6>
          <div className="d-flex flex-center">
            <RatingSimple score={rating} className="mr-3 fs-sm" />
            <div className={`date text-tertiary ${isBrowser && 'fw-bold'}`}>
              {dayjs(date).format('MMMM D, YYYY')}
            </div>
          </div>
        </div>
        <p className="text-secondary">{review}</p>
      </div>
    </div>
  );
};

export default UserReview;