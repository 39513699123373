import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDropzone } from 'react-dropzone'
import { Alert, Avatar, FormGroup } from '@material-ui/core';
import { withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import { axiosInstance } from '../../axiosConfig';
import { useUserContext } from '../../contexts/UserContext';
import toast from 'react-hot-toast';

const AddMedicalRecordDialog = ({ user, record, open, onClose, onSuccessfulSave, addRecordDialogMeta }) => {

  const { user: authUser } = useUserContext();

  const [state, setState] = React.useState({
    file: null,
    isLargeFile: false,
    name: '',
    inProgress: false
  })

  const setFile = file => {
    console.log(file)
    if (file.size > 10 * 1024 * 1024) {
      setState({ ...state, file, isLargeFile: true })
    } else {
      setState({ ...state, file, isLargeFile: false })
    }
  }

  const cancel = e => {
    setState({
      ...state,
      inProgress: false,
      isLargeFile: false,
      name: '',
      file: null
    })

    onClose()
  }

  const save = e => {
    setState({ ...state, inProgress: true })
    let formData = new FormData();
    formData.append('file', state.file);
    formData.append('name', state.name);
    formData.append('type', 'file');
    if (addRecordDialogMeta) {
      formData.append('meta', addRecordDialogMeta);
    }

    let req = `/users/${user.id}/medical-records`;
    if (record) {
      req = `/users/${user.id}/medical-records/${record}`;
    }
    axiosInstance.post(req, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        // timeout: 1200000,
      }
    ).then(response => {
      onSuccessfulSave(response);
      setState({
        ...state,
        inProgress: false,
        isLargeFile: false,
        name: '',
        file: null
      })
    }).catch(err => {
      toast.error('Error adding medical record')
    }).finally(() => {
      setState({ ...state, inProgress: false })
    })
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="d-flex flex-center">
          {authUser.id === user.id
            ? <><Avatar src={withCdnUrl(user.photo)} />&nbsp;&nbsp; Upload Medical Record</>
            : <><Avatar src={withCdnUrl(user.photo)} />&nbsp;&nbsp; {getDisplayName(user)}</>
          }
        </DialogTitle>
        <DialogContent>

          <MyDropzone
            selectFile={file => setFile(file)}
          />

          <FormGroup className="mt-2">
            <TextField
              value={state.name}
              onChange={e => setState({ ...state, name: e.target.value })}
              required
              margin="dense"
              id="name"
              label="Name"
              placeholder="Enter medical record description"
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true, }}
              inputProps={{ maxLength: 90 }}
              variant="outlined"
            />
          </FormGroup>

          {state.isLargeFile &&
            <Alert severity="info">َUploading a large file may take a few minutes.</Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button
            disabled={state.name.length < 1 || !state.file || state.inProgress}
            onClick={save}
          >
            {state.inProgress ?
              <>
                <CircularProgress color="secondary" style={{ width: '16px', height: '16px' }} className="mr-2" />
            Please Wait
             </>
              : <>Save</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


function MyDropzone(props) {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '35px',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'rgb(210,210,210)',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#333',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject } = useDropzone({
      maxFiles: 1,
      accept: 'image/jpeg, image/png, application/pdf, video/mp4',
      onDrop: files => files.map(file => props.selectFile(file))
    })

  const style = {
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }

  const files = acceptedFiles.map(file => (
    <span key={file.path}>
      {file.path} - {file.size / 1000} KB
    </span>
  ));

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <p className="text-secondary m-0" >Drag and drop your file here or click</p>
      <p className="text-tertiary m-0 fs-sm" >Allowed file types: .jpg, .png, .pdf, .mp4</p>
      {files.length > 0 &&
        <div className="mt-2 d-flex">
          Selected file: &nbsp;{files}
        </div>
      }
    </div>
  )
}


export default AddMedicalRecordDialog;