const BellIcon = ({ color, size = 22, className, outline }) => {
  outline = outline ? true : false;

  return (
    <>
      {!outline ?
        <svg width={size} height={size} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.8 29.3" fill="none">
          <path d="M11.4 29.3a3 3 0 003-3h-6a3 3 0 003 3zm9-9v-7.6c0-4.6-2.5-8.4-6.8-9.4v-1a2.2 2.2 0 10-4.5 0v1c-4.3 1-6.7 4.8-6.7 9.5v7.4l-2 2c-.9 1-.2 2.5 1 2.5h19.8c1.4 0 2-1.6 1.1-2.5z" fill={color || '#333333'} />
        </svg>
        : <svg width={size} height={size} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.8 29.3" fill="none">
          <path d="M11.4 29.3a3 3 0 003-3h-6a3 3 0 003 3zm9-9v-7.6c0-4.6-2.5-8.4-6.8-9.4v-1a2.2 2.2 0 10-4.5 0v1c-4.3 1-6.7 4.8-6.7 9.5v7.4l-2 2c-.9 1-.2 2.5 1 2.5h19.8c1.4 0 2-1.6 1.1-2.5z" fill={color || '#333333'} />
        </svg>
      }
    </>
  );
};

export default BellIcon;