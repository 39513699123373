import { TextField } from '@material-ui/core';
import React from 'react';

const TextfieldWithCounter = props => {
  let remaining = props.inputProps.maxLength - props.value.length;
  let remainingPercent = remaining/props.inputProps.maxLength * 100 ;
  let color = 'gray';

  if(remainingPercent < 30) color = 'var(--colors-yellow)';
  if(remainingPercent < 20) color = 'var(--colors-red)';
  
  return (
    <span style={{position: 'relative'}}>
    <TextField
    {...props}/>
    <span style={{
      position: 'absolute',
      right: '1.3rem',
      bottom: '1.3rem',
      color: color,
      // fontWeight: 'bold',
    }}>{props.value.length}/{props.inputProps.maxLength}</span>
    </span>
  );
};

export default TextfieldWithCounter;