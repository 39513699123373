import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import UserContext from '../../contexts/UserContext';

import CountrySelectDropdown from '../../components/CountySelectDropdown/CountrySelectDropdown';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';

import './AccountInformation.scss'

class AccountInformation extends Component {

  static contextType = UserContext;

  state = {
    firstName: this.context.user.firstName,
    lastName: this.context.user.lastName,
    gender: this.context.user.gender || -1,
    country: this.context.user.country || -1,
    province: this.context.user.province || '',
    city: this.context.user.city || '',
    address: this.context.user.address || '',
    about: this.context.user.bio || '',
    phone: this.context.user.phone || '',

    // consultant fields
    specialty: this.context.user.specialties[0] || -1,
    language: this.context.user.languages[0] || -1,
    boardNumber: this.context.user.boardNumber || '',
    bankAccountInformation: this.context.user.bankAccountInformation || '',

    // employee's field
    company: this.context.user.company || '',

    // employers fields
    companyName: this.context.user.companyName || '',
    companyRegistrationNumber: this.context.user.companyRegistrationNumber || '',
    companyEmployeesCount: this.context.user.companyEmployeesCount || '',

    inProgress: false,
    errors: {},
    touchedFields: [],
    passwordDialogOpen: false,
    oldPass: '',
    newPass: '',
    confirmPass: '',
  }

  componentDidMount() {
    document.title = "BankWell | AccountInformation";
  }

  handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = {
        ...this.state,
        [e.target.name]: e.target.checked,
        //touchedFields: [ ...this.state.touchedFields, e.target.name ]
      };
      this.setState(newState);
    } else {
      newState = {
        ...this.state,
        [e.target.name]: e.target.value,
        //touchedFields: [ ...this.state.touchedFields, e.target.name]
      }
      this.setState(newState);
    }
  }

  validateForm = e => {
    let newState = {
      ...this.state,
      touchedFields: [...this.state.touchedFields, e.target.name, e.target.name]
    };
    this.setState(newState);

    let errors = {}

    if ('employer' === this.context.user.type) {
      if (!this.state.companyName || this.state.companyName.length < 3) {
        errors.companyName = 'Please enter at least 3 characters';
      }
      if (!this.state.companyRegistrationNumber) {
        errors.companyRegistrationNumber = 'Please fill out this field';
      }
      if ('' === this.state.companyEmployeesCount) {
        errors.companyEmployeesCount = 'Please enter a valid number';
      }
    }

    if (['consultant', 'freelancer'].includes(this.context.user.type)) {
      if (!this.state.firstName || this.state.firstName.length < 3) {
        errors.firstName = 'Please enter at least 3 characters';
      }
      if (!this.state.lastName || this.state.lastName.length < 3) {
        errors.lastName = 'Please enter at least 3 characters';
      }
    }

    if ('consultant' === this.context.user.type) {
      if (!this.state.bio || this.state.bio.length < 3) {
        errors.bio = 'Please add your bio';
      }
      if (!this.state.boardNumber) {
        errors.boardNumber = 'Please fill out this field';
      }
      if (!this.state.bankAccountInformation) {
        errors.bankAccountInformation = 'Please fill out this field';
      }
    }

    if (!this.state.country || -1 === this.state.country) {
      errors.country = 'Please select your country';
    }
    if ('' === this.state.province) {
      errors.province = 'Please fill out this field';
    }
    if ('' === this.state.city) {
      errors.city = 'Please fill out this field';
    }
    if ('' === this.state.address) {
      errors.address = 'Please fill out this field';
    }

    let phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!this.state.phone || !phoneRegex.test(this.state.phone)) {
      errors.phone = 'Please enter a valid phone number';
    }

    // remove errors for non-touched field
    Object.keys(errors).forEach(err => {
      if (!newState.touchedFields.includes(err)) {
        delete errors[err]
      }
    })

    this.setState({ ...newState, errors })
  }

  errors = response => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all required fields')
    }
    if ('MISMATCH_PASSWORDS' === response.data.code) {
      toast.error('Password mismatch')
    }
    if ('INVALID_PASSWORD' === response.data.code) {
      toast.error('Invalid Password')
    }
    if ('WEAK_PASSWORD' === response.data.code) {
      toast.error('Please choose stronger password')
    }
    if ('ALREADY_EXISTS' === response.data.code && 'phone' === response.data.vars) {
      toast.error('Phone number already taken')
    }
    if (!response.data.code && 400 === response.status) {
      toast.error('Form fields are not valid')
    }
  }

  updateProfile = e => {
    e.preventDefault();

    this.setState({ inProgress: true })
    const formData = new URLSearchParams();

    formData.append('country', this.state.country);
    formData.append('province', this.state.province);
    formData.append('city', this.state.city);
    formData.append('address', this.state.address);
    formData.append('phone', this.state.phone);

    if (['consultant', 'freelancer', 'employee'].includes(this.context.user.type)) {
      formData.append('firstName', this.state.firstName);
      formData.append('lastName', this.state.lastName);
      formData.append('gender', this.state.gender);
    }

    if ('consultant' === this.context.user.type) {
      formData.append('bio', this.state.about);
      formData.append('specialties', [this.state.specialty]);
      formData.append('languages', [this.state.language]);
      formData.append('boardNumber', this.state.boardNumber);
      formData.append('bankAccountInformation', this.state.bankAccountInformation);
    }

    if ('employer' === this.context.user.type) {
      formData.append('companyName', this.state.companyName);
      formData.append('companyRegistrationNumber', this.state.companyRegistrationNumber);
      formData.append('companyEmployeesCount', this.state.companyEmployeesCount);
    }

    const config = {}

    axiosInstance.patch(`/users/${this.context.user.id}`, formData, config)
      .then(response => {
        if (response.status === 200) {
          this.context.setUser(response.data)
          toast.success('Profile updated');
        }
      }).catch(error => {
        this.errors(error.response)
      }).finally(() => {
        this.setState({ inProgress: false })
      })
  }

  changePassword = e => {
    axiosInstance.patch(`users/${this.context.user.id}/password`,{
        password: this.state.oldPass,
        newPassword: this.state.newPass,
        confirmPassword: this.state.confirmPass,
    }).then(response => {
      toast.success('Password changed successfully');
      this.setState({passwordDialogOpen:false})
    }).catch(err => {
      this.errors(err.response)
    })
  }

  render() {
    // let { user } = this.context;
    let state = this.state;

    return (
      <div className="AccountInformation">
        <h6 className="fg-primary fw-bold b-b-regular pb-2">Your personal data</h6>

        <div className="section changePassword pt-3 pb-3 b-b-regular mb-3">
          <Button
            onClick={e => this.setState({ passwordDialogOpen: true })}
          >Change Password</Button>
          <Dialog open={this.state.passwordDialogOpen} onClose={() => this.setState({ passwordDialogOpen: false })}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <form autoComplete="off" onSubmit={this.changePassword}>
              <TextField
                autoFocus
                InputLabelProps={{ shrink: true, }}
                margin="dense"
                id="name"
                name="oldPass"
                onChange={this.handleChange}
                label="Old password"
                placeholder="Enter your current password"
                type="password"
                fullWidth
                variant="standard"
              />
              <TextField
                InputLabelProps={{ shrink: true}}
                inputProps={{autocomplete:"false"}}
                margin="dense"
                id="newPass"
                name="newPass"
                onChange={this.handleChange}
                label="Password"
                placeholder="New password"
                type="password"
                fullWidth
                variant="standard"
              />
              <TextField
                InputLabelProps={{ shrink: true, }}
                inputProps={{autocomplete:"false"}}
                margin="dense"
                id="confirmPass"
                name="confirmPass"
                onChange={this.handleChange}
                label="Confirm"
                placeholder="Re-type new password"
                type="password"
                fullWidth
                variant="standard"
              />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ passwordDialogOpen: false })}>Cancel</Button>
              <Button onClick={this.changePassword}>Change Password</Button>
            </DialogActions>
          </Dialog>
        </div>

        {['consultant', 'freelancer', 'employee'].includes(this.context.user.type) &&
          <>
            <TextField
              error={state.errors.firstName ? true : false}
              helperText={state.errors.firstName}
              onBlur={this.validateForm}
              inputProps={{ maxLength: 20 }}
              required
              value={state.firstName}
              // autoFocus
              margin="dense"
              id="firstName"
              name="firstName"
              label="Legal First Name"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Tap to edit"
              onChange={this.handleChange}
            />
            <TextField
              error={state.errors.lastName ? true : false}
              helperText={state.errors.lastName}
              onBlur={this.validateForm}
              inputProps={{ maxLength: 20 }}
              value={state.lastName}
              required
              // autoFocus
              margin="dense"
              id="lastName"
              name="lastName"
              label="Legal Last Name"
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
            />
            <div className="mt-2 mb-0">
              <FormControl variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={state.gender}
                  onChange={this.handleChange}
                >
                  <MenuItem value="-1" disabled>
                    <span className="text-tertiary"> Tap to select </span>
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        }

        {'consultant' === this.context.user.type &&
          <>
            <div className="mt-2 mb-0">
              <FormControl required variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
                <InputLabel id="specialty">Specialty</InputLabel>
                <Select
                  labelId="specialty"
                  id="specialty"
                  name="specialty"
                  displayEmpty
                  value={state.specialty}
                  onChange={this.handleChange}
                >
                  <MenuItem value="-1" disabled>
                    <span className="text-tertiary"> Tap to select </span>
                  </MenuItem>
                  <MenuItem value="chiropractic">Chiropractic</MenuItem>
                  <MenuItem value="kinesiology">Kinesiology</MenuItem>
                  <MenuItem value="physiotherapy">Physiotherapy</MenuItem>
                  <MenuItem value="gynecology">Gynecology</MenuItem>
                  <MenuItem value="psychology">Psychology</MenuItem>
                  <MenuItem value="nutrition">Nutrition</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="mt-2 mb-0">
              <FormControl required variant="standard" className="mt-2 mb-2" style={{ width: '100%' }}>
                <InputLabel id="language">Language</InputLabel>
                <Select
                  labelId="language"
                  id="language"
                  name="language"
                  displayEmpty
                  value={state.language}
                  onChange={this.handleChange}
                >
                  <MenuItem value="-1" disabled>
                    <span className="text-tertiary"> Tap to select </span>
                  </MenuItem>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                  <MenuItem value="fa">Persian</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TextField
              error={state.errors.boardNumber ? true : false}
              helperText={state.errors.boardNumber}
              onBlur={this.validateForm}
              value={state.boardNumber}
              required
              fullWidth
              margin="dense"
              id="boardNumber"
              name="boardNumber"
              label="Board Number"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
            />
            <TextField
              error={state.errors.bankAccountInformation ? true : false}
              helperText={state.errors.bankAccountInformation}
              onBlur={this.validateForm}
              value={state.bankAccountInformation}
              required
              // autoFocus
              margin="dense"
              id="bankAccountInformation"
              name="bankAccountInformation"
              label="Bank Account Information"
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
            />

          </>
        }

        {'employer' === this.context.user.type &&
          <>
            <TextField
              error={state.errors.companyName ? true : false}
              helperText={state.errors.companyName}
              inputProps={{ maxLength: 30, }}
              value={state.companyName}
              required
              // autoFocus
              margin="dense"
              id="companyName"
              name="companyName"
              label="Legal Company Name"
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
              onBlur={this.validateForm}
            />
            <TextField
              error={state.errors.companyRegistrationNumber ? true : false}
              helperText={state.errors.companyRegistrationNumber}
              inputProps={{ maxLength: 30 }}
              value={state.companyRegistrationNumber}
              required
              // autoFocus
              margin="dense"
              id="companyRegistrationNumber"
              name="companyRegistrationNumber"
              label="Company Registration Number"
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
              onBlur={this.validateForm}
            />
            <TextField
              error={state.errors.companyEmployeesCount ? true : false}
              helperText={state.errors.companyEmployeesCount}
              inputProps={{ maxLength: 30, }}
              value={state.companyEmployeesCount}
              required
              // autoFocus
              margin="dense"
              id="companyEmployeesCount"
              name="companyEmployeesCount"
              label="Number of Employees"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
              onBlur={this.validateForm}
            />
          </>
        }

        {'employee' === this.context.user.type &&
          <>
            <TextField
              error={state.errors.company ? true : false}
              helperText={state.errors.company}
              inputProps={{ maxLength: 30, }}
              value={state.company}
              required
              disabled={true}
              // autoFocus
              margin="dense"
              id="company"
              name="company"
              label="Company"
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder="Tap to edit"
              variant="standard"
              onChange={this.handleChange}
              onBlur={this.validateForm}
            />
          </>
        }

        <TextField
          error={state.errors.phone ? true : false}
          helperText={state.errors.phone}
          onBlur={this.validateForm}
          value={state.phone}
          required
          disabled={true}
          fullWidth
          // autoFocus
          margin="dense"
          id="phone"
          name="phone"
          label="Phone Number"
          type="text"
          InputLabelProps={{ shrink: true, }}
          placeholder="Tap to edit"
          variant="standard"
          onChange={this.handleChange}
        />

        {'consultant' === this.context.user.type &&
          <TextField
            error={state.errors.about ? true : false}
            helperText={state.errors.about}
            inputProps={{ minLength: 3, maxLength: 500 }}
            onBlur={this.validateForm}
            value={state.about}
            required
            // autoFocus
            margin="dense"
            id="about"
            name="about"
            label="About"
            type="text"
            fullWidth
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Tap to edit"
            maxRows={4}
            variant="standard"
            onChange={this.handleChange}
          />
        }

        <div className="mt-2 mb-0">
          <CountrySelectDropdown
            error={state.errors.country}
            value={this.state.country}
            onChange={this.handleChange} />
        </div>

        <TextField
          error={state.errors.province ? true : false}
          helperText={state.errors.province}
          inputProps={{ maxLength: 30, minLength: 2 }}
          onBlur={this.validateForm}
          value={state.province}
          required
          // autoFocus
          margin="dense"
          id="province"
          name="province"
          label="Province"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Tap to edit"
          variant="standard"
          onChange={this.handleChange}
        />

        <TextField
          error={state.errors.city ? true : false}
          helperText={state.errors.city}
          inputProps={{ maxLength: 30, minLength: 2 }}
          onBlur={this.validateForm}
          value={state.city}
          required
          // autoFocus
          margin="dense"
          id="city"
          name="city"
          label="City"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Tap to edit"
          variant="standard"
          onChange={this.handleChange}
        />

        <TextField
          error={state.errors.address ? true : false}
          helperText={state.errors.address}
          inputProps={{ minLength: 3, maxLength: 300 }}
          onBlur={this.validateForm}
          value={state.address}
          required
          // autoFocus
          margin="dense"
          id="address"
          name="address"
          label="Address"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Tap to edit"
          variant="standard"
          onChange={this.handleChange}
        />

        <div className="mt-5 mb-5 text-center">
          <Button variant="contained" disabled={state.inProgress || Object.keys(state.errors).length > 0} onClick={this.updateProfile}>
            {state.inProgress && <CircularProgress color="secondary" style={{ width: '16px', height: '16px' }} className="mr-2" />}
                   Update Profile
                    </Button>
        </div>
      </div>
    );
  }
}

export default AccountInformation;