import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import { getDisplayName } from '../../utils/user';
import { Avatar, Rating } from '@material-ui/core';
import { withCdnUrl } from '../../utils/common';
import TextfieldWithCounter from '../TextfieldWithCounter/TextfieldWithCounter';
import {axiosInstance} from '../../axiosConfig';
import toast from 'react-hot-toast';

const AddTherapistReviewDialog = props => {

  const [state, setState] = React.useState({
    review: '',
    professionalism: 0,
    diagnosis: 0,
    behavior: 0,
  })

  const errors = response => {
    if ('MISSING_FIELDS' === response.data.code) {
      toast.error('Please fill all fields')
    }
    if ('ALLOWED_REVIEWS_COUNT_EXCEEDED' === response.data.code) {
      toast.error('You can rate this therapist after booking a meeting.')
    }
    if ('INVALID_SCORES_RANGE' === response.data.code) {
      toast.error('Invalid scores values')
    }
  }

  const submit = () => {
    axiosInstance.post(`reviews`, {
      user: props.user.id,
      professionalism: state.professionalism,
      diagnosis: state.diagnosis,
      behavior: state.behavior,
      comment: state.review
    }).then(response => {
      props.onAdd()
    }).catch(err => {
      errors(err.response)
    })
    
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle className="d-flex flex-center">
          <><Avatar src={withCdnUrl(props.user.photo)} />&nbsp;&nbsp; {getDisplayName(props.user)}</>
        </DialogTitle>
        <DialogContent>
          <div className="ratingWrapper">
            <div className="rating d-flex flex-space-between mb-2">
              <div className="label text-secondary">Professionalism</div>
              <div className="ratingStars">
                <Rating
                  precision={.5}
                  name="professionalism"
                  style={{ color: 'var(--primary-color)' }}
                  defaultValue={0}
                  onChange={e => { setState({ ...state, professionalism: e.target.value }) }}
                />
              </div>
            </div>
            <div className="rating d-flex flex-space-between mb-2">
              <div className="label text-secondary">Diagnosis</div>
              <div className="ratingStars">
                <Rating
                  precision={.5}
                  name="diagnosis"
                  style={{ color: 'var(--primary-color)' }}
                  defaultValue={0}
                  onChange={e => { setState({ ...state, diagnosis: e.target.value }) }}
                />
              </div>
            </div>
            <div className="rating d-flex flex-space-between mb-2">
              <div className="label text-secondary">Behavior</div>
              <div className="ratingStars">
                <Rating
                  precision={.5}
                  name="behavior"
                  style={{ color: 'var(--primary-color)' }}
                  defaultValue={0}
                  onChange={e => { setState({ ...state, behavior: e.target.value }) }}
                />
              </div>
            </div>
          </div>

          <FormGroup>
            <TextfieldWithCounter
              value={state.review}
              onChange={e => setState({ ...state, review: e.target.value })}
              required
              margin="dense"
              id="review"
              label="Review"
              name="review"
              placeholder="Please share your experience..."
              type="text"
              fullWidth
              multiline
              InputLabelProps={{
                shrink: true,
              }}
              rows={3}
              inputProps={{ maxLength: 500 }}
              variant="outlined"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={submit}
            color="primary"
            disabled={!state.professionalism || !state.behavior || !state.diagnosis}
          >
            Submit Review
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTherapistReviewDialog;