import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ScheduleMeetingDialog from '../../components/ScheduleMeetingDialog/ScheduleMeetingDialog';
import ScheduleMeetingConfirmDialog from '../../components/ScheduleMeetingConfirmDialog/ScheduleMeetingConfirmDialog';
import PaymentDialog from '../../components/PaymentDialog/PaymentDialog';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { getDisplayName } from '../../utils/user';
import dayjs from 'dayjs';
import { formatPrice } from '../../utils/common';
import recordIcon from '../../assets/icons/record.svg';
import UserContext from '../../contexts/UserContext';
import { Link } from 'react-router-dom';

class MeetingButton extends Component {

  static contextType = UserContext

  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  state = {
    selectedDate: new Date(),
    scheduleDialogOpen: false,
    scheduleReviewDialogOpen: false,
    appointment: null,
    paymentDialogOpen: false,
    paymentResultDialogOpen: false,
    questionnairesDialogOpen: false
  }

  openDialog = () => {
    if (this.context.isAuthenticated) {
      this.setState({ scheduleDialogOpen: true })
    } else {
      this.context.setAuthPopupOpen(true)
    }
  }

  render() {
    let buttonProps = { ...this.props };
    const buttonIcon = buttonProps.iconComponent;

    delete buttonProps.user;
    delete buttonProps.buttonLabel;
    delete buttonProps.asIconButton;
    delete buttonProps.iconComponent;

    let questionnairesNeeded = this.context.user.questionnaires && Object.keys(this.context.user.questionnaires).length > 0 && Object.values(this.context.user.questionnaires).filter(x => x !== true).length > 0;

    return (
      <>
        {this.props.asIconButton ?
          <Button
            {...buttonProps}
            variant="contained"
            style={{
              display: 'flex',
              padding: 0,
              width: '40px',
              height: '40px',
              minWidth: '40px',
              borderRadius: '100%'
            }}
            // disabled={!this.context.isAuthenticated}
            onClick={() => this.setState({ scheduleDialogOpen: true })}
          >
            {buttonIcon ? buttonIcon
              : <img src={recordIcon} alt="rec" />}
          </Button>
          :
          <Button
            {...buttonProps}
            variant="contained"
            // disabled={!this.context.isAuthenticated}
            onClick={() => this.openDialog()}
          >
            {buttonIcon ? buttonIcon
              : <img src={recordIcon} alt="rec" className="mr-2" />}
            {this.props.buttonLabel || 'Meet'}
          </Button>
        }

        <ScheduleMeetingDialog
          open={this.state.scheduleDialogOpen}
          user={this.props.user}
          onSelect={response => {
            this.setState({
              scheduleDialogOpen: false,
              scheduleReviewDialogOpen: true,
              appointment: response.data
            })
          }}
          onClose={() => this.setState({ scheduleDialogOpen: false })}
        />
        {this.state.appointment &&
          <>
            <ScheduleMeetingConfirmDialog
              open={this.state.scheduleReviewDialogOpen}
              appointment={this.state.appointment}
              onSubmit={() => {
                this.setState({
                  scheduleReviewDialogOpen: false,
                  paymentDialogOpen: true
                })
              }}
              onClose={() => this.setState({ scheduleReviewDialogOpen: false })}
            />
            <PaymentDialog
              open={this.state.paymentDialogOpen}
              appointment={this.state.appointment}
              onClose={() => this.setState({ paymentDialogOpen: false })}
              onSuccessPayment={response => {
                // check for questionnaires forms
                this.context.getAuthUser(() => {
                  this.setState({
                    paymentDialogOpen: false,
                    paymentResultDialogOpen: true
                  })
                });
              }}
            />

            <Dialog
              open={this.state.paymentResultDialogOpen}
              PaperProps={{ className: 'pl-4 pr-4 pt-2 pb-2' }}
              onClose={() => this.setState({ paymentResultDialogOpen: false })}
              className="centered"
            >
              <DialogTitle id="alert-dialog-title"> Appointment successfully set </DialogTitle>
              <DialogContent>
                <div className="infoWrapper">
                  <div className="info mb-2">
                    {getDisplayName(this.state.appointment.consultant)}, {this.state.appointment.consultant.specialties[0]}
                  </div>
                  <div className="info mb-2">
                    {this.days[dayjs(this.state.appointment.start).format('d')]},&nbsp; {dayjs(this.state.appointment.start).format('MMMM DD, YYYY')}
                  </div>
                  <div className="info mb-2"> {dayjs(this.state.appointment.end).diff(this.state.appointment.start, 'minutes')} minutes session </div>
                  <div className="info mb-2">
                    {formatPrice(this.state.appointment.payment.amount)}
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                {questionnairesNeeded
                  ? <Button
                    onClick={() => this.setState({ paymentResultDialogOpen: false,questionnairesDialogOpen: true })}
                    color="primary"
                    variant="contained"
                    disableElevation
                    style={{ borderRadius: '30px', textTransform: 'inherit' }}
                  >
                    Ok
                  </Button>
                  : <Button
                    component={Link}
                    onClick={() => this.setState({ paymentResultDialogOpen: false })}
                    to="/panel/appointments"
                    color="primary"
                    variant="contained"
                    disableElevation
                    style={{ borderRadius: '30px', textTransform: 'inherit' }}
                  >
                    Go to appointments
                  </Button>
                }
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.questionnairesDialogOpen}
              PaperProps={{ className: 'pl-4 pr-4 pt-2 pb-2' }}
              onClose={() => this.setState({ questionnairesDialogOpen: false })}
              className="centered"
            >
              <DialogTitle id="alert-dialog-title">Questionnaires forms needed</DialogTitle>
              <DialogContent>
                You should fill the related questionnaire before meeting. So your therapist will have a better view of your condition. Click "Download Forms" to get started.
              </DialogContent>

              <DialogActions>
                <Button
                  component={Link}
                  onClick={() => this.setState({ questionnairesDialogOpen: false })}
                  to="/panel/appointments"
                  color="primary"
                  variant="outline"
                  disableElevation
                  style={{ borderRadius: '30px', textTransform: 'inherit' }}
                >Later</Button>
                <Button
                  component={Link}
                  onClick={() => this.setState({ questionnairesDialogOpen: false })}
                  to="/panel/medical-records"
                  color="primary"
                  variant="contained"
                  disableElevation
                  style={{ borderRadius: '30px', textTransform: 'inherit' }}
                >Download Forms</Button>
              </DialogActions>
            </Dialog>

          </>
        }
      </>
    );
  }
}

export default MeetingButton;