import React, { createContext, useContext } from 'react';
import { axiosInstance } from '../axiosConfig';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export default UserContext;

export class AuthProvider extends React.Component {
  
  state = {
    user: { id: 0 },
    newNotifications: 0,
    token: '',
    isAuthenticated: false,
    authConnectionProblem: false,
    loaded: false,
    authPopupOpen: false
  }
  updatedSocket = false;
  notificationTimer = null;

  componentDidMount() {
    this.getAuthUser();
  }

  getAuthUser = cb => {
    axiosInstance.get('users/me').then(response => {
      this.setUser(response.data);
      this.setAuthenticated(true);
      this.getNotifications();

      if (!this.notificationTimer) {
        this.notificationTimer = setInterval(() => {
          this.getNotifications()
        }, 30000);
      }
      cb && cb();
    }).catch(() => {
      this.setState({ loaded: true })
    })
  }

  getNotifications = () => {
    axiosInstance.get('notifications').then(response => {
      this.setNewNotificationCount(response.data.new)
    }).catch(() => {
      this.setState({ loaded: true })
    })
  }

  setUser = user => {
    this.setState({ user, loaded: true })
  }

  setAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }

  setNewNotificationCount = count => {
    this.setState({ newNotifications: +count })
  }

  logout = () => {
    axiosInstance.delete('/account/logout').catch(err => { })
    this.setState({
      isAuthenticated: false,
      user: {},
      authPopupOpen: false
    })
  }

  setAuthPopupOpen = isOpen => {
    this.setState({
      authPopupOpen: isOpen || false
    })
  }

  render() {
    return (
      <UserContext.Provider value={{
        getAuthUser: this.getAuthUser,
        user: this.state.user,
        setUser: this.setUser,
        isAuthenticated: this.state.isAuthenticated,
        setAuthenticated: this.setAuthenticated,
        newNotifications: this.state.newNotifications,
        getNotifications: this.getNotifications,
        setNewNotificationCount: this.setNewNotificationCount,
        loaded: this.state.loaded,
        authPopupOpen: this.state.authPopupOpen,
        setAuthPopupOpen: this.setAuthPopupOpen,
        connectError: this.state.authConnectionProblem,
        logout: this.logout
      }}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}


