import { Avatar } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../axiosConfig';
import { useUserContext } from '../../contexts/UserContext';
import { withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import AvatarEdit from '../AvatarEdit/AvatarEdit';
import UserMenu from '../UserMenu/UserMenu';

const UserSidebar = ({ user, menu }) => {

  const { user: authUser, setUser } = useUserContext();
  const [inProgress, setInprogress] = React.useState(false);
  const updatePhoto = e => {
    setInprogress(true)
    let formData = new FormData();
    formData.append('photo', e.target.files[0])
    axiosInstance.patch(`users/${authUser.id}/photo`, formData).then(response => {
      setUser(response.data)
    }).catch(() => {
      toast.error('Failed to upload photo')
    })
      .finally(() => { setInprogress(false) })
  }

  return (
    <>
      {user
        ?
        <>
          <Avatar
            style={{width: '150px', height: '150px'}}
            src={withCdnUrl(user.photo)}
          />
          <h1 className="fs-lg mt-3 mb-2">{getDisplayName(user)}</h1>
          <div className="text-tertiary">{user.company}</div>
        </>
        :
        <>
          <AvatarEdit
            size="150"
            photo={authUser.photo}
            onChange={updatePhoto}
            inProgress={inProgress} />
          <h1 className="fs-lg mt-3 mb-2">{getDisplayName(authUser)}</h1>
          <div className="text-tertiary">Joined {dayjs(authUser.joinDate).format('MMMM D, YYYY')}</div>
          <div className="mt-3"> <UserMenu /> </div>
        </>
      }
    </>
  );
};

export default UserSidebar;