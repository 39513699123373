
import  CircularProgress  from '@material-ui/core/CircularProgress';
import React from 'react';

const Splash = () => {
  return (
    <div style={{
      position: 'absolute',
      zIndex: 100,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CircularProgress />
    </div>
  );
};

export default Splash;