import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import dayjs from 'dayjs';
import React from 'react';
import { withCdnUrl } from '../../utils/common';
import { getDisplayName } from '../../utils/user';
import CameraIcon from '../icons/CameraIcon';
import MenuIcon from '../icons/MenuIcon';
import { Link } from 'react-router-dom';
import EyeIcon from '../icons/EyeIcon';
import { useUserContext } from '../../contexts/UserContext';
import StopIcon from '../icons/StopIcon';
import MeetingButton from '../MeetingButton/MeetingButton';
import { isBrowser } from 'react-device-detect';
import RefreshIcon from '../../components/icons/RefreshIcon';

const AppointmentItem = ({ appointment, isArchive, onCancel }) => {

  const { user } = useUserContext();
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleMenuClick = e => setAnchorEl(e.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)
  const [open, setOpen] = React.useState(false);
  const isOwner = user.id === appointment.user.id

  let avatarStyle = isBrowser ?
    {
      width: '80px',
      height: '80px'
    } :
    {
      width: '40px',
      height: '40px'
    }

  return (
    <div className="d-flex flex-center pt-3 pb-3">
      <div className="avatarWrapper">
        {isOwner
          ?
          <Link to={`/therapist/${appointment.consultant.id}`}>
            <Avatar src={withCdnUrl(appointment.consultant.photo)} style={avatarStyle} />
          </Link>
          : <Avatar src={withCdnUrl(appointment.user.photo)} style={avatarStyle} />
        }
      </div>
      <div className="contentWrapper flex-fill ml-3">
        <h6 className="fs-sm d-flex flex-center whitespace-nowrap">
          {'cancel' === appointment.status
            ? <CameraIcon color={'var(--colors-red)'} className="mr-2" />
            : <CameraIcon color={'var(--primary-color)'} className="mr-2" />
          }
          {dayjs(appointment.start).format('MMMM D, YYYY H:mm')}
        </h6>
        <div className="name text-secondary">
          {isOwner
            ? getDisplayName(appointment.consultant)
            : getDisplayName(appointment.user)
          }
        </div>
        {!isOwner &&
          <div className="company text-tertiary">{appointment.user.company || ''}</div>
        }
      </div>
      <div className="actionsWrapper d-flex flex-center">
        {isOwner}
        {!isArchive &&
          <Button
            disableElevation
            variant="contained"
            // href={appointment.meetingUrl}
            href={'https://meet.google.com'}
            target="_blank"
            style={!isBrowser ? {
              display: 'flex',
              padding: 0,
              width: '40px',
              height: '40px',
              minWidth: '40px',
              borderRadius: '100%'
            }: { borderRadius: '30px' }}
          >
            {isBrowser 
            ? <><CameraIcon color="white" className="mr-2" />Start</>
            : <CameraIcon color="white" />
            }
 
        </Button>
        }

        {isOwner && isArchive &&
          <MeetingButton
            user={appointment.consultant}
            disableElevation
            asIconButton={!isBrowser}
            iconComponent={<RefreshIcon color="white" className={isBrowser && 'mr-2'}/>}
            variant="contained"
            style={{ borderRadius: '30px' }}
            buttonLabel="Meet Again"
          />
        }

        {(!isOwner || (isOwner && !isArchive)) &&
          <IconButton
            onClick={handleMenuClick}
            style={{
              marginLeft: '.4rem',
              padding: '.7rem'
            }}
          ><MenuIcon color="#333" size="15" /></IconButton>
        }

        {isOwner ?
          <>
            {!isArchive ?
              <Menu
                anchorEl={anchorEl}
                keepMounted
                style={{ minWidth:'200px' }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Button}
                  style={{ textTransform: 'inherit', minWidth:'200px' }}
                  //href={appointment.meetingUrl}
                  href={'https://meet.google.com'}
                  target="_blank"
                ><CameraIcon outline className="mr-2" /> Start </MenuItem>
                <MenuItem
                  onClick={() => setOpen(true)}
                ><StopIcon outline className="mr-2" /> Cancel </MenuItem>
              </Menu>
              :
              <></>
            }
          </>
          : <>
            {!isArchive ?
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Button}
                  style={{ textTransform: 'inherit' }}
                  href={appointment.meetingUrl}
                  target="_blank"
                ><CameraIcon outline className="mr-2" /> Start </MenuItem>
                <MenuItem
                  component={Link}
                  to={`/user/${appointment.user.id}/medical-records`}
                ><EyeIcon outline className="mr-2" /> View Medical Records </MenuItem>
              </Menu>
              :
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Link}
                  to={`/user/${appointment.user.id}/medical-records`}
                ><EyeIcon outline className="mr-2" /> View Medical Records </MenuItem>
              </Menu>
            }
          </>
        }

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Cancellation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to cancel this appointment?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>No</Button>
            <Button onClick={() => { setOpen(false); onCancel() }} color="error">
              Yes
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AppointmentItem;