import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { axiosInstance } from '../../axiosConfig';
import toast from 'react-hot-toast';
import { useUserContext } from '../../contexts/UserContext';
import { isValidEmail, isValidPassword } from '../../utils/common';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

const Login = props => {
  let invitationCode = props.invitationCode;
  const { user, setUser, isAuthenticated, setAuthenticated } = useUserContext();
  const [state, setState] = React.useState({
    step: 1,
    remember: true,
    email: '',
    emailValid: true,
    emailTouch: false,
    password: '',
    passwordValid: true,
    passwordTouch: false,
    confirm: '',
    type: null,
    error: '',
  });

  React.useEffect(() => {
    if (!isAuthenticated && invitationCode) {
      getInvitedUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = { ...state, [e.target.name]: e.target.checked };
      setState(newState);
    } else {
      newState = { ...state, [e.target.name]: e.target.value }
      setState(newState);
    }

    validateForm(newState)
  }

  const getInvitedUser = () => {
    axiosInstance.get(`invitations/${invitationCode}`).then(response => {
      setState({...state, email: response.data.email})
    }).catch(() => {
      toast.error('Invalid invitation code');
      props.history.push('/')
      props.onSuccessLogin()
    })
  }

  const validateForm = newState => {
    let emailValid = true;
    let passwordValid = true;

    if (!isValidEmail(newState.email)) {
      emailValid = false
    }

    if (!isValidPassword(newState.password)) {
      passwordValid = false
    }

    setState({ ...newState, passwordValid, emailValid })
  }

  const errors = response => {
    if ('USER_NOT_FOUND' === response.data.code) {
      toast.error('User not found')
    }
    if ('INVALID_VERIFICATION_CODE' === response.data.code) {
      toast.error('Invalid verification code')
    }
    if (401 === response.status) {
      toast.error('Invalid email or password')
    }
    if (409 === response.status) {
      toast.error('User already exists')
    }
  }

  const login = event => {
    event.preventDefault();
    const userForm = new URLSearchParams();
    userForm.append('login', state.email);
    userForm.append('password', state.password);

    if (state.remember) {
      userForm.append('remember', state.remember);
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    axiosInstance.post("/account/login", userForm, config)
      .then(result => {
        if (result.status === 200) {
          setState({ ...state, isLoggedIn: true })
          setUser(result.data)
          setAuthenticated(true)
          props.onSuccessLogin()

          if('employer' === result.data.type){
            props.history.push('/panel/employees')
          }
          if('consultant' === result.data.type){
            props.history.push('/panel/appointments')
          }
        } else {
          setState({ ...state, isError: true })
        }
      }).catch(error => {
        errors(error.response)
      });
  }

  const register = event => {
    event.preventDefault();
    const userForm = new URLSearchParams();
    userForm.append('email', state.email);
    userForm.append('password', state.password);
    userForm.append('timezone', dayjs.tz.guess());
    if(invitationCode){
      userForm.append('invitationCode', invitationCode);
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    axiosInstance.post("/users", userForm, config)
      .then(result => {
        if (result.status === 201) {
          if(invitationCode){
            invitationCode = null;
            setUser({ ...user, type: 'employee', email: state.email , registrationUncompleted: true })
            setAuthenticated(true)
          } else {
            setUser({ ...user, email: state.email })
            setState({ ...state, step: 2 })
          }
        }
      }).catch(error => {
        errors(error.response)
      })
  }

  const verify = event => {
    event.preventDefault();

    const userForm = new URLSearchParams();
    userForm.append('email', state.email);
    userForm.append('code', state.confirm);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    axiosInstance.patch("/account/verify", userForm, config)
      .then(result => {
        if (result.status === 200) {
          setState({ ...state, step: 3 })
        }
      }).catch(error => {
        errors(error.response)
      })
  }

  const setType = event => {
    event.preventDefault();

    const userForm = new URLSearchParams();
    userForm.append('email', state.email);
    userForm.append('type', state.type);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    axiosInstance.patch("/users/n/type", userForm, config)
      .then(result => {
        if (result.status === 200) {
          setUser({ ...user, type: state.type, registrationUncompleted: true })
          setAuthenticated(true)
          // props.history.push('/register')
        }
      }).catch(error => {
        errors(error.response)
      })
  }

  return (
    <>
      {1 === state.step &&
        <div className="p-5">
          <form onSubmit={login}>
            {!invitationCode
              ? <DialogTitle className="text-center">Sign in or signup to get access to the service</DialogTitle>
              : <DialogTitle className="text-center">Signup to get access to the service</DialogTitle>
            }
            <DialogContent style={{ maxWidth: '320px', margin: '0 auto' }}>
              <TextField
                error={!state.emailValid}
                value={state.email}
                disabled={!!invitationCode}
                // autoFocus
                margin="dense"
                id="email"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                required
                InputLabelProps={{ shrink: true, }}
                placeholder="Email Address"
                onChange={handleChange}
              />
              <TextField
                error={!state.passwordValid}
                margin="dense"
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                required
                InputLabelProps={{ shrink: true, }}
                placeholder="Password"
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.remember}
                    onChange={handleChange}
                    name="remember"
                    color="primary"
                  />
                }
                label="Remember me"
              />

            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              {!invitationCode &&
                <Button
                  type="submit"
                  disabled={!state.email || !state.emailValid || !state.password || !state.passwordValid}
                  variant="contained"
                  style={{ borderRadius: '30px', boxShadow: 'none' }}
                  onClick={login}
                >Sign In</Button>
              }

              <Button
                disabled={!state.email || !state.emailValid || !state.password || !state.passwordValid}
                variant="contained"
                style={{ borderRadius: '30px', boxShadow: 'none' }}
                onClick={register}
              >Sign Up</Button>
            </DialogActions>
            {!invitationCode &&
              <DialogActions style={{ justifyContent: 'center' }}>
                <Button size="small" color="primary" onClick={props.forget}>Forget Password</Button>
              </DialogActions>
            }
          </form>
        </div>
      }

      {2 === state.step &&
        <div className="p-5">
          <DialogTitle className="text-center">Confirmation code</DialogTitle>
          <p className="p-0 m-0 mt-1 text-center" style={{ fontSize: '20px' }}>We’ve sent a confirmation code to your email. Please enter it here!</p>
          <DialogContent style={{ maxWidth: '320px', margin: '0 auto' }}>
            <TextField
              margin="dense"
              id="confirm"
              name="confirm"
              label="Confirmation Code"
              type="text"
              fullWidth
              variant="outlined"
              required
              InputLabelProps={{ shrink: true, }}
              placeholder="Confirmation Code"
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              disabled={state.confirm.length < 3}
              variant="contained" style={{ borderRadius: '30px', boxShadow: 'none' }} onClick={verify}>Next</Button>
          </DialogActions>
        </div>
      }

      {3 === state.step &&
        <div className="p-5">
          <DialogTitle className="text-center">Choose an option describes you best</DialogTitle>
          <DialogContent style={{ maxWidth: '320px', margin: '0 auto' }}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="type" name="type" value={state.type} onChange={handleChange}>
                <FormControlLabel value="freelancer" control={<Radio />} label="I am a Freelancer" />
                <FormControlLabel value="consultant" control={<Radio />} label="I am a Therapist" />
                <FormControlLabel value="employer" control={<Radio />} label="I am an Employer" />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button variant="contained" style={{ borderRadius: '30px', boxShadow: 'none' }} onClick={setType}>Next</Button>
          </DialogActions>
        </div>
      }

    </>
  );
};

export default withRouter(Login);