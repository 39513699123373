import React, { Component } from 'react';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { axiosInstance } from '../../axiosConfig';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactShowMoreText from 'react-show-more-text';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Pagination from '../../components/Pagination/Pagination';
import UserCard from '../../components/UserCard/UserCard';
import style from './TherapistsList.module.scss';

import specialtyIcon from '../../assets/icons/paragraph.svg';
import dateIcon from '../../assets/icons/date.svg';
import worldIcon from '../../assets/icons/world.svg';
import searchIconGray from '../../assets/icons/search-2.svg';
import searchIcon from '../../assets/icons/search.svg';
import { DatePicker } from '@material-ui/lab';
import { isBrowser } from 'react-device-detect';
import { Button, Input, ListItemText } from '@material-ui/core';
import CloseIcon from '../../components/icons/CloseIcon';

class TherapistsList extends Component {

  query = queryString.parse(this.props.location.search);

  state = {
    searchBoxVisible: isBrowser ? true : false,
    langs: {
      en: {
        code: 'en',
        name: 'English',
        checked: queryString.parse(this.props.location.search).l?.split('|')?.includes('en')
      },
      fr: {
        code: 'fr',
        name: 'French',
        checked: queryString.parse(this.props.location.search).l?.split('|')?.includes('fr')
      },
      fa: {
        code: 'fa',
        name: 'Persian',
        checked: queryString.parse(this.props.location.search).l?.split('|')?.includes('fa')
      },
    },
    users: [],
    page: 1,
    total: null,
    pageSize: 5,
    specialty: queryString.parse(this.props.location.search).spec || '-1',
    selectedDate: (queryString.parse(this.props.location.search).date && dayjs(+queryString.parse(this.props.location.search).date)) || null,
    datePickerOpen: false,
    loading: false,
  }

  componentDidMount() {
    document.title = "BankWell | Therapists";
    this.updateResults();
  }

  updateFilters = () => {
    this.query = queryString.parse(this.props.location.search);

    if ('-1' !== this.state.specialty) {
      this.query.spec = this.state.specialty;
    } else {
      delete this.query.spec
    }

    let langFilter = [
      this.state.langs.fa.checked && 'fa',
      this.state.langs.en.checked && 'en',
      this.state.langs.fr.checked && 'fr'
    ].filter(Boolean).join('|')

    if (langFilter) {
      this.query.l = langFilter;
    } else {
      delete this.query.l
    }

    if (this.state.selectedDate) {
      this.query.date = encodeURI(dayjs(this.state.selectedDate).valueOf())
    } else {
      delete this.query.date
    }

    if (!this.query.date) {
      delete this.query.date
    }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: queryString.stringify(this.query)
    })
  }

  updateResults = () => {

    this.updateFilters()

    let q = {
      type: 'consultant',
      size: this.state.pageSize,
      page: this.state.page,
      spec: this.state.specialty,
      lang: this.query.l,
      date: dayjs(this.state.selectedDate).valueOf()
    }

    this.state.specialty === '-1' && delete q.spec
    this.state.selectedDate || delete q.date

    this.setState({ loading: true })
    axiosInstance.get(`/users`, { params: q }).then(response => {
      this.setState({
        users: response.data.data,
        total: response.data.total,
        loading: false
      })

    }).catch(err => { })
  }

  updatePage = page => {
    if (page >= 1) {
      this.setState({ page }, () => {
        this.updateResults()
      })
    }
  }

  handleLangChange = (e, update = true) => {
    let newState = {};
    if (!e.target.name) { // it comes from select tag in mobile mode
      let selected = e.target.value;
      newState.langs = this.state.langs;
      Object.keys(this.state.langs).forEach(lang => {
        newState.langs[lang].checked = selected.includes(lang)
      })
    } else {
      newState = {
        langs: {
          ...this.state.langs,
          [e.target.name]: {
            ...this.state.langs[e.target.name],
            checked: e.target.checked
          }
        }
      };
    }
    this.setState(newState, () => { update && this.updateResults() });
  }

  handleChange = e => {
    let newState = {};
    if ('checkbox' === e.target.type) {
      newState = { [e.target.name]: e.target.checked };
      this.setState(newState, () => { this.updateResults() });
    } else {
      newState = { [e.target.name]: e.target.value }
      this.setState(newState, () => { /*this.updateFilters()*/ });
    }
  }

  handleDateChange = selectedDate => {
    selectedDate = dayjs(selectedDate).startOf('day').toDate();
    this.setState({ selectedDate }, () => { /*this.updateFilters()*/ });
  };

  toggleSearch = () => {
    this.setState({ searchBoxVisible: !this.state.searchBoxVisible })
  }

  render() {
    let selectedLanguages = [];
    Object.keys(this.state.langs).forEach((lang) => {
      if (this.state.langs[lang].checked) {
        selectedLanguages.push(lang)
      }
    })

    return (
      <div className="Consultants">
        <Header backButton={true}
          actions={
            <>
            {! isBrowser &&
              <IconButton onClick={this.toggleSearch}>
              <img src={searchIcon}
              width="24px"
              aria-label="Toggle Search"
              />
              </IconButton>
            }
            </>
          } />

        {this.state.searchBoxVisible &&
          <div className={`searchWrapper ${style.searchWrapper}`}>
            <div className="container">
              {isBrowser &&
                <span className="mr-2">Search:</span>
              }
              <div className="row flex-fill">
                <div className="col-xs-12 col-md-6">
                  <div className="specialtyFilterWrapper d-flex">
                    <FormControl variant="standard" className={`${style.specialtySelect}`} fullWidth>
                      <InputLabel variant="standard"><img src={specialtyIcon} alt="s" /> Specialty: </InputLabel>
                      <Select
                        fullWidth
                        labelId="specialty"
                        id="specialty"
                        name="specialty"
                        value={this.state.specialty}
                        onChange={this.handleChange}
                      >
                        <MenuItem value="-1" > Select Specialty </MenuItem>
                        <MenuItem value="chiropractic">Chiropractic</MenuItem>
                        <MenuItem value="kinesiology">Kinesiology</MenuItem>
                        <MenuItem value="physiotherapy">Physiotherapy</MenuItem>
                        <MenuItem value="gynecology">Gynecology</MenuItem>
                        <MenuItem value="psychology">Psychology</MenuItem>
                        <MenuItem value="nutrition">Nutrition</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <DatePicker
                    className={style.dateSelect}
                    open={this.state.datePickerOpen}
                    onClose={() => this.setState({ datePickerOpen: false })}
                    reduceAnimations={true}
                    value={this.state.selectedDate}
                    onChange={(newValue) => { this.handleDateChange(newValue); }}

                    InputProps={{
                      endAdornment: this.state.selectedDate && (
                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ selectedDate: null, datePickerOpen: false }, () => { this.updateResults(true) });
                        }}>
                          <CloseIcon color={'#555'} size={18} />
                        </IconButton>
                      )
                    }}
                    InputAdornmentProps={{
                      position: "start",
                      style: { order: 1, marginLeft: '.5rem', marginRight: 0 }
                    }}
                    renderInput={({ inputProps, InputProps, inputRef }) => {
                      return (
                        <FormControl className={`${style.dateSelect}`}>
                          <FormLabel><img src={dateIcon} alt="date" /> When: </FormLabel>
                          <InputBase fullWidth {...inputProps}  {...InputProps} placeholder="Select Date" ref={inputRef} onClick={e => this.setState({ datePickerOpen: true })} />
                        </FormControl>
                      )
                    }}

                  />
                </div>

                {!isBrowser &&
                  <div className="col-xs-12 col-md-6">
                    <div className="specialtyFilterWrapper d-flex">
                      <FormControl variant="standard" className={`${style.languageSelect}`} fullWidth>
                        {selectedLanguages.length
                          ? <InputLabel variant="standard"><img src={worldIcon} alt="s" /> Language:</InputLabel>
                          : <InputLabel variant="standard"><img src={worldIcon} alt="s" /> Language: All</InputLabel>
                        }
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedLanguages}
                          onChange={e => { this.handleLangChange(e, false) }}
                          input={<Input label="Tag" />}
                          renderValue={selected => {
                            let langs = [];
                            selected.forEach(item => langs.push(this.state.langs[item].name));
                            return langs.length ? langs.join(', ') : 'All Languages'
                          }}
                        >
                          {Object.entries(this.state.langs).map((lang) => {
                            lang = lang[1]
                            return (
                              <MenuItem key={lang.code} value={lang.code}>
                                <Checkbox checked={lang.checked} />
                                <ListItemText primary={lang.name} />
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }

                {!isBrowser &&
                  <div className="col-xs-12 col-md-6">
                    <Button
                      fullWidth
                      onClick={this.updateResults}
                      style={{ background: 'white' }}>Search</Button>
                  </div>
                }
              </div>
              {isBrowser &&
                <IconButton onClick={this.updateResults} className={style.searchButton}><img src={searchIconGray} alt="search" aria-label="search" /></IconButton>
              }
            </div>
          </div>
        }

        <div className={`Search container content ${style.page}`}>
          {isBrowser &&
            <aside className={style.sidebar}>
              <div className="widget">
                <h5 className="widget__title">Language</h5>
                <div className="widget__body">
                  {Object.entries(this.state.langs).map(lang => {
                    lang = lang[1]
                    return (
                      <div className="wrap" key={lang.code}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={lang.checked}
                              onChange={e => this.handleLangChange(e, true)}
                              name={lang.code}
                              color="primary"
                            />
                          }
                          label={lang.name}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="widget">
                <h5 className="widget__title">What is BankWell</h5>
                <div className="widget__body text-secondary">
                  <ReactShowMoreText
                    lines={4}
                    more="read more"
                    less="show less"
                    className="content-css"
                    anchorClass="read-more"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    <p>Bankwell is the first paramedical benefits bank platform connecting
                      people with the care and services they need for better health,
                      aiming at preventing medical conditions which may occur in
                      their environment and workplace before they reach the point
                      of requiring medical involvement and treatment.
                    </p>
                    <p>The only platform offering a holistic approach to preventive
                      physical and mental health care in the work environment and
                      providing AI insights, predictions and suggestions to employers
                      regarding the problems which may most commonly occur in
                      their type of work environment and recommended
                      preventative measures.
                    </p>
                </ReactShowMoreText>
                </div>
              </div>

              <div className="widget">
                <h5 className="widget__title">FAQ</h5>
                <div className="widget__body text-secondary">
                  <p>
                    How does BankWell differ from other services that claim they give preventional helthcare?
                </p>
                  <p>How do I get matched up?</p>
                  <p>
                    Do I need a test call before starting my ordinary session with a consultant?</p>
                </div>
              </div>
            </aside>
          }

          <main className={style.main}>

            <div className="users">
              <>
                {this.state.loading && <CircularProgress style={{ display: 'block', margin: '2rem auto' }} />}
                {!this.state.loading && this.state.users.length === 0
                  && <h5>Sorry, We cannot find any Therapist at the moment.</h5>
                }

                {!this.state.loading && this.state.users.length > 0 && this.state.users.map(user =>
                  <UserCard user={user} key={user.id} />
                )
                }
              </>
            </div>
            <Pagination
              className={style.pagination}
              page={this.state.page}
              total={this.state.total}
              pageSize={this.state.pageSize}
              onPageChange={this.updatePage}
              resetScroll={true}
            />
          </main>
        </div>

        <Footer />
      </div>
    );
  }
}

export default TherapistsList;