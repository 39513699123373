import React from 'react';
import queryString from 'query-string';
import { useUserContext } from '../../contexts/UserContext';
import { isBrowser, isMobile } from 'react-device-detect';
import { Button, Dialog } from '@material-ui/core';
import Login from '../Login/Login';
import ForgetPass from '../ForgetPass/ForgetPass';
import closeIcon from '../../assets/icons/close.svg';
import { withRouter } from 'react-router-dom';

const AuthDialogs = props => {
  const { isAuthenticated, authPopupOpen, setAuthPopupOpen } = useUserContext();
  const invitationCode = queryString.parse(props.location.search).r || null;

  const [state, setState] = React.useState({
    component: 'login',
    invitationCode: invitationCode || null
  })

  React.useEffect(()=> {
    if(invitationCode){
      setAuthPopupOpen(true)
    }
    if(authPopupOpen){
      setState({ ...state, component: 'login'})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authPopupOpen])

  const closePopup = () => {
    setAuthPopupOpen(false)
    setState({ ...state, invitationCode: null})
  }

  const openForget = () => setState({ ...state, component: 'forget' })
  const openLogin = () => setState({ ...state, component: 'login' })


  return (
    <div>
      {!isAuthenticated &&
        <Dialog open={authPopupOpen}
          onClose={closePopup}
          className="authDialog"
          fullScreen={isMobile ? true : false}
        >
          {'login' === state.component &&
            <Login onSuccessLogin={closePopup} forget={openForget} invitationCode={state.invitationCode} />
          }
          {'forget' === state.component &&
            <ForgetPass onSuccessLogin={closePopup} login={openLogin} />
          }

          {!isBrowser &&
            <Button
              variant="contained"
              style={{
                position: 'absolute',
                bottom: '3rem',
                left: 'calc(50% - 30px)',
                width: '60px',
                height: '60px',
                padding: 0,
                lineHeight: '60px',
                borderRadius: '100%',
                margin: '0 auto',
                minWidth: '40px',
                background: 'white'
              }}
              onClick={closePopup} color="secondary">
              <img src={closeIcon} alt="close" width="20px" height="20px" />
            </Button>
          }
        </Dialog>
      }
    </div>
  );
};

export default withRouter(AuthDialogs);